import { getNoteTemplates } from 'apis'
import { useCallback, useEffect, useState } from 'react'

let lastData: IOption[] = []
const useGetNoteTemplates = () => {
  const [state, setState] = useState<{ loading: boolean; template?: IOption[] }>({
    loading: true,
    template: lastData || [],
  })
  const { loading, template } = state
  const getData = useCallback(() => {
    getNoteTemplates<{ Templates: string[] }>({}).then((res) => {
      const temp = (res?.Templates || []).map((item) => ({ label: item, value: item }))
      lastData = temp
      setState({ loading: false, template: temp })
    })
  }, [])
  useEffect(() => {
    getData()
  }, [])
  return {
    loading,
    template,
  }
}

export default useGetNoteTemplates
