import React, { useCallback, useState } from 'react'
import { Box, Button, Dialog as DialogUi, IconButton, Typography, Drawer } from '@mui/material'
import classes from './ContactUsDialog.module.less'
import { Close } from '@mui/icons-material'
import CustomTextField from '../common/CustomTextField'
import { submitFeedback } from 'apis'
import SnackBar from '../snackBar'

interface Props {
  onClose: () => void
}

const ContactUsDialog: React.FC<Props> = ({ onClose }) => {
  const [data, setData] = useState<string>()
  const [open, setOpen] = useState(true)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onSend = async () => {
    await submitFeedback({ FeedbackText: data }).then(() => {
      SnackBar({ type: 'success', msg: "Thanks for your feedback! We'll circle shortly." })
      onCancel()
    })
  }

  const onCancel = () => {
    removeDialog()
  }
  const onInputChange = useCallback((value: string, name: string) => {
    setData(value)
  }, [])
  return (
    <DialogUi disableEscapeKeyDown open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      <Typography className={classes.title}>Contact us</Typography>
      <Typography className={classes.desc}>Your message</Typography>
      <CustomTextField
        multiline
        value={data}
        placeholder="Share your feedback, ask a question or report a bug here..."
        rootClassName={classes.textInput}
        sx={{ width: '100%' }}
        onInputChange={onInputChange}
      />
      <Box className={classes.bottom}>
        <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSend} color="primary" className={classes.btn} disabled={!data}>
          Send
        </Button>
      </Box>
    </DialogUi>
  )
}

export const H5ContactUsDialog: React.FC<Props> = ({ onClose }) => {
  const [data, setData] = useState<string>()
  const [open, setOpen] = useState(true)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onSend = async () => {
    await submitFeedback({ FeedbackText: data }).then(() => {
      SnackBar({ type: 'success', msg: "Thanks for your feedback! We'll circle shortly." })
      onCancel()
    })
  }

  const onCancel = () => {
    removeDialog()
  }
  const onInputChange = useCallback((value: string, name: string) => {
    setData(value)
  }, [])
  return (
    <Drawer anchor="bottom" open={open} onClose={removeDialog} classes={{ paper: classes.h5container }}>
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      <Typography className={classes.title}>Contact us</Typography>
      <Typography className={classes.desc}>Your message</Typography>
      <CustomTextField
        multiline
        value={data}
        placeholder="Share your feedback, ask a question or report a bug here..."
        rootClassName={classes.textInput}
        sx={{ width: '100%' }}
        onInputChange={onInputChange}
      />
      <Box className={classes.bottom}>
        <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSend} color="primary" className={classes.btn} disabled={!data}>
          Send
        </Button>
      </Box>
    </Drawer>
  )
}

export default ContactUsDialog
