import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useSetState } from 'hooks'
import classes from './AdminDashboard.module.less'
import Images from 'assets'
// import { ArrowOutward, SouthEast } from '@mui/icons-material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import PeriodSelect from 'components/common/PeriodSelect'
import { PERIOD_OPTIONS } from 'utils/common'
import { getConsultationsSummary, getUsersConsultationSummary } from 'apis'
const Summary = {
  UserCount: {
    label: 'Active Doctors',
    icon: Images.doctors,
    number: 0,
    percent: -0.24,
  },
  ConsultationCount: {
    label: 'Consultations',
    icon: Images.consultations,
    number: 0,
    percent: 0,
  },
  DurationSum: {
    label: 'Consultation Hours',
    icon: Images.hours,
    number: 0,
    percent: 0.24,
  },
}

const columns: GridColDef[] = [
  {
    field: 'UserName',
    headerName: 'Doctor Name',
    sortable: false,
    disableColumnMenu: true,
    width: 200,
    valueGetter: ({ row }) => row?.UserName?.en || '',
  },
  { field: 'Email', headerName: 'Email', sortable: false, disableColumnMenu: true, width: 250 },
  {
    field: 'ConsultationCount',
    headerName: 'Number Of Consultations',
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    minWidth: 230,
  },
  {
    field: 'DurationSum',
    headerName: 'Number Of Consultation Hours',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    minWidth: 280,
  },
]

const AdminDashboard = () => {
  const [state, setState] = useSetState({
    page: 0,
    pageSize: 50,
    period: PERIOD_OPTIONS.find((item) => item.value === '7-Day'),
    render: false,
    rows: [] as Record<string, any>[],
    loading: true,
    rowCount: 0,
  })
  const { page, pageSize, period, rows, loading, rowCount } = state
  const tu = period?.value

  useEffect(() => {
    getConsultationsSummary({ tu }).then((res: any) => {
      Summary.UserCount.number = res.UserCount
      Summary.ConsultationCount.number = res.ConsultationCount
      Summary.DurationSum.number = res.DurationSum
      setState((pre) => ({ ...pre, render: !pre.render }))
    })
  }, [tu])

  useEffect(() => {
    setState({ loading: true })
    getUsersConsultationSummary({ tu, skip: page * pageSize, take: pageSize })
      .then((res: any) => {
        const rows = res?.Users || []
        setState((pre) => ({ ...pre, rows, rowCount: res?.Total }))
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [tu, page, pageSize])

  return (
    <Box className={classes.layout}>
      <Box className={classes.header}>
        <span className={classes.headerText}>Admin Dashboard</span>
        <PeriodSelect period={period} onChange={(value) => setState({ period: value, page: 0 })} label="" />
      </Box>

      <Box className={classes.body}>
        <Box className={classes.summary}>
          {Object.entries(Summary).map(([key, item], index) => {
            // const color = item.percent >= 0 ? '#2AA33A' : '#D3543A'
            return (
              <Box key={index} className={classes.summaryItem}>
                <Box>
                  <Typography className={classes.leftText}>{item.label}</Typography>
                  <Typography className={classes.number}>{item.number.toLocaleString('en-US')}</Typography>
                  {/* <Typography className={classes.percent} sx={{ color }}>
                    {item.percent >= 0 ? '+' : '-'}
                    <span style={{ margin: '0 4px' }}>{Math.abs(item.percent)}</span>
                    {item.percent >= 0 ? <ArrowOutward /> : <SouthEast />}
                  </Typography> */}
                </Box>
                <img src={item.icon} alt="logo" />
              </Box>
            )
          })}
        </Box>

        <Box className={classes.table}>
          <Typography className={classes.tableTitle}>Table of active Doctors</Typography>
          <Box style={{ overflowX: 'auto' }}>
            <DataGrid
              rows={rows}
              rowCount={rowCount}
              columns={columns}
              rowSelection={false}
              classes={{
                virtualScroller: classes.virtualScroller,
                row: classes.row,
                cell: classes.cell,
                columnHeader: classes.columnHeader,
                columnSeparator: classes.columnSeparator,
              }}
              paginationMode="server"
              paginationModel={{
                pageSize,
                page,
              }}
              onPaginationModelChange={(pagination) => {
                setState({
                  page: pagination.pageSize === pageSize ? pagination.page : 0,
                  pageSize: pagination.pageSize,
                })
              }}
              loading={loading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default AdminDashboard
