import React, { forwardRef, useCallback } from 'react'
import { FormControl, FormHelperText, TextField, Theme } from '@mui/material'
import Input from '@mui/material/Input'
import MaskedInput from 'react-text-mask'
import classnames from 'classnames'
import { SxProps } from '@mui/system'
import classes from './MobilePhone.module.less'
import { useDebouncedCallback } from 'use-debounce'

interface Information {
  placeholder?: string
  value?: string
  onChangePhone?: (value: string) => void
  sx?: SxProps<Theme>
}

const TextMaskCustom = forwardRef((props, ref) => {
  // @ts-ignore
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      name="textPhone"
      ref={(ref: any) => {
        inputRef?.(ref ? ref.inputElement : null)
      }}
      guide={false}
      showMask
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      id="maskedInput"
    />
  )
})

const MobilePhone = (props: Information) => {
  const { value = '', onChangePhone, sx } = props
  const phoneValue = value?.length === 11 ? value.slice(1) : value
  const onChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    let result = value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
    result = result?.length === 10 ? `1${result}` : result
    onChangePhone && onChangePhone(result)
  }, 300)

  const onBlur = () => {
    let result = value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
    result = result?.length === 10 ? `1${result}` : result
    onChangePhone && onChangePhone(result)
  }

  return (
    <TextField
      variant="outlined"
      fullWidth
      onBlur={onBlur}
      onChange={onChange}
      value={phoneValue}
      InputProps={{
        inputComponent: TextMaskCustom as any,
        classes: {
          root: classnames(classes.phoneInput),
        },
      }}
    />
  )
}

export default MobilePhone
