export const NOTE_TEMPLATE: IOption[] = [
  { label: 'General Medicine', value: 'General Medicine' },
  { label: 'Oncology', value: 'Oncology' },
  { label: 'Pain Medicine and Neuroscience Prompt', value: 'Pain Medicine and Neuroscience Prompt' },
  { label: 'Psychiatry', value: 'Psychiatry' },
  { label: 'SOAP', value: 'SOAP' },
  { label: 'Surgery', value: 'Surgery' },
]

export const LANGUAGE_ENUM: IOption[] = [
  { label: 'English', value: 'en' },
  { label: 'Mandarin', value: 'zh' },
  { label: 'Spanish', value: 'es' },
  { label: 'Vietnamese', value: 'vi' },
]
