import React, { useState } from 'react'
import { Box, Dialog as DialogUi, Typography, Checkbox, Button, Drawer } from '@mui/material'
import classes from './HIPAADialog.module.less'
import { Close } from '@mui/icons-material'
import Config from 'constants/Config'
import { useSetState } from 'hooks'
import { actions } from 'app/profileSlice'
import { useAppDispatch } from 'app/hooks'
import { acceptedAgreement } from 'apis'

interface Props {
  onClose: () => void
}

const UpdateDialog: React.FC<Props> = ({ onClose }) => {
  const [{ termsChecked, open }, setState] = useSetState({
    termsChecked: false,
    open: true,
  })
  const dispatch = useAppDispatch()

  const removeDialog = () => {
    acceptedAgreement({ Agreement: 'Agreement2' }).then(() => {
      dispatch(actions.updateUser({ AgreementAcceptedV2: true }))
      setState({ open: false })
      onClose?.()
    })
  }

  return (
    <DialogUi open={open} classes={{ paper: classes.container }}>
      <Typography sx={{ fontSize: '32px', color: '#333', fontWeight: 'bold' }}>
        Important Update: New Terms and Conditions
      </Typography>
      <Typography sx={{ fontSize: '20px', color: '#333', marginTop: '16px' }}>
        We've recently updated our Subscriber Agreement and Business Associate Agreement (BAA) to further enhance
        security and HIPAA compliance. Please review these documents and accept the new terms to continue using Copilot.
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
        <Checkbox
          checked={termsChecked}
          size="small"
          sx={{ padding: 0, marginRight: '12px' }}
          onChange={() => {
            setState({ termsChecked: !termsChecked })
          }}
        />
        <Typography className={classes.agreement}>
          I have read and agree to the updated
          <a href={Config.agreement} target="_blank" rel="noreferrer">
            Subscriber Agreement {''}
          </a>
          and
          <a href={Config.terms} target="_blank" rel="noreferrer">
            {'Business Associate Agreement (BAA).'}
          </a>
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={removeDialog}
        color="primary"
        disabled={!termsChecked}
        sx={{ width: '50%', margin: '48px auto 24px' }}
      >
        Accept and Continue
      </Button>
    </DialogUi>
  )
}

export const H5UpdateDialog: React.FC<Props> = ({ onClose }) => {
  const [{ termsChecked, open }, setState] = useSetState({
    termsChecked: false,
    open: true,
  })
  const dispatch = useAppDispatch()

  const removeDialog = () => {
    acceptedAgreement({ Agreement: 'Agreement2' }).then(() => {
      dispatch(actions.updateUser({ AgreementAcceptedV2: true }))
      setState({ open: false })
      onClose?.()
    })
  }

  return (
    <Drawer anchor="bottom" open={open} classes={{ paper: classes.h5UpdatePaper }}>
      <Typography sx={{ fontSize: '30px', color: '#333', fontWeight: 'bold' }}>New Terms and Conditions</Typography>
      <Typography sx={{ fontSize: '20px', color: '#8C8C8C', marginTop: '16px' }}>
        We've recently updated our Subscriber Agreement and Business Associate Agreement (BAA) to further enhance
        security and HIPAA compliance. Please review these documents and accept the new terms to continue using Copilot.
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
        <Checkbox
          checked={termsChecked}
          size="small"
          sx={{ padding: 0, marginRight: '12px' }}
          onChange={() => {
            setState({ termsChecked: !termsChecked })
          }}
        />
        <Typography className={classes.agreement}>
          I have read and agree to the updated
          <a href={Config.agreement} target="_blank" rel="noreferrer">
            Subscriber Agreement {''}
          </a>
          and
          <a href={Config.terms} target="_blank" rel="noreferrer">
            {'Business Associate Agreement (BAA).'}
          </a>
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={removeDialog}
        color="primary"
        disabled={!termsChecked}
        sx={{ width: '80%', margin: '24px auto' }}
      >
        Accept and Continue
      </Button>
    </Drawer>
  )
}

export default UpdateDialog
