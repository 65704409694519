import { Route, Link, useLocation, Outlet, useHref } from 'react-router-dom'
import React, { lazy, Suspense, LazyExoticComponent, ComponentType, useEffect } from 'react'
import { isMobile } from 'utils/common'
import Layout from 'pages/Layout'
import Login from 'pages/Login'
import Transcript, { TranscriptWrapper } from 'pages/Transcript'
import SignUp from 'pages/SignUp'
import HistoryList from 'pages/HistoryList'
import AdminDashboard from 'pages/AdminDashboard'
import ResetPassword from 'pages/ResetPassword'
import AdminConsultations from 'pages/AdminConsultations'
import ExternalHtml from 'pages/ExternalHtml'
import Settings from 'pages/Settings'
import { useNavigate } from 'react-router/dist/lib/hooks'
import { addCheckInLog } from './apis'
import WPeckerInfo from './pages/WPeckerInfo'
import OutsideSales from './pages/OutsideSales'
import PdfOutsideSales from './pages/PdfOutsideSales'
const MobileLayout = lazy(() => import('h5/MobileLayout'))
const H5SignUp = lazy(() => import('h5/SignUp'))
const H5Settings = lazy(() => import('h5/Settings'))
const H5Transcript = lazy(() => import('h5/Transcript'))
const H5HistoryList = lazy(() => import('h5/HistoryList'))
const H5Login = lazy(() => import('h5/Login'))
const H5ResetPassword = lazy(() => import('h5/ResetPassword'))

const withSuspense = (Component: LazyExoticComponent<ComponentType<any>>) => (
  <Suspense fallback="">{React.createElement(Component)}</Suspense>
)
function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}
const RouterHistory: React.FC = () => {
  const location = useLocation()
  console.log('location', location)
  const addLog = (url: string) => {
    ////"PCS”表示web端，"h5"表示mobile端
    addCheckInLog({ PageUrl: url, VisitorType: isMobile ? 'h5' : 'PCS' })
  }
  useEffect(() => {
    addLog(location.pathname)
  }, [location])
  useEffect(() => {
    const listener = function () {
      if (document.visibilityState === 'visible') {
        addLog(location.pathname)
      } else if (document.visibilityState === 'hidden') {
        // 页面变为不可见状态时执行的操作
      }
    }
    document.addEventListener('visibilitychange', listener)
    return () => {
      document.removeEventListener('visibilitychange', listener)
    }
  }, [location])
  return <Outlet />
}
const Router = (
  <Route element={<RouterHistory />}>
    <Route path="/signUp" element={<SignUp />} />
    <Route path="/login" element={<Login />} />
    <Route path="/pdfOutsideSales/:id" element={<PdfOutsideSales />} />
    <Route path="/externalHtml" element={<ExternalHtml />} />
    <Route path="/WPeckerInfo/:type" element={<WPeckerInfo />} />
    <Route path="/resetPassword" element={<ResetPassword />} />
    <Route path="/" element={<Layout />}>
      <Route index element={<Transcript />} />
      <Route path="/history" element={<HistoryList />} />
      <Route path="/history/:id" element={<TranscriptWrapper />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/adminDashboard" element={<AdminDashboard />} />
      <Route path="/adminConsultations" element={<AdminConsultations />} />
      <Route path="/outsidesales" element={<OutsideSales />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  </Route>
)

const H5Router = (
  <Route element={<RouterHistory />}>
    <Route path="/signUp" element={withSuspense(H5SignUp)} />
    <Route path="/login" element={withSuspense(H5Login)} />
    <Route path="/externalHtml" element={<ExternalHtml />} />
    <Route path="/WPeckerInfo/:type" element={<WPeckerInfo />} />
    <Route path="/resetPassword" element={withSuspense(H5ResetPassword)} />
    <Route path="/" element={withSuspense(MobileLayout)}>
      <Route index element={withSuspense(H5Transcript)} />
      <Route path="/history" element={<H5HistoryList />} />
      <Route path="/history/:id" element={withSuspense(H5Transcript)} />
      <Route path="/settings" element={withSuspense(H5Settings)} />
      <Route path="/adminDashboard" element={<AdminDashboard />} />
      <Route path="/adminConsultations" element={<AdminConsultations />} />
      <Route path="/outsidesales" element={<OutsideSales />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  </Route>
)

export default isMobile ? H5Router : Router
