import React from 'react'
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  FormControl,
  FormGroup,
  FormLabel,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import classes from './FeedbackDialog.module.less'
import { Close } from '@mui/icons-material'
import CustomTextField from 'components/common/CustomTextField'
import { submitConsultationFeedback } from 'apis'
import { useSetState } from 'hooks'
import Images from 'assets'
import SnackBar from 'components/snackBar'

interface Props {
  onClose: () => void
  ConsultationId: string
}

const FeedbackDialog: React.FC<Props> = ({ onClose, ConsultationId }) => {
  const [{ open, Rating, Areas, Comment }, setState] = useSetState({
    open: true,
    Rating: 0,
    Areas: [] as string[],
    Comment: '',
  })

  const removeDialog = () => {
    setState({ open: false })
    onClose?.()
  }
  const onSend = () => {
    submitConsultationFeedback({ Rating, Areas, Comment, Source: 'Desktop', ConsultationId }).then((res: any) => {
      SnackBar({
        msg: (
          <>
            <Typography sx={{ color: '#333', fontWeight: 'bold', fontSize: '20px' }}>
              Thank you for providing your feedback!
            </Typography>
            <Typography sx={{ color: '#333', fontSize: '16px' }}>
              We appreciate your input and will use it to improve our services. If you have further questions, let us
              know.
            </Typography>
          </>
        ),
        type: 'success',
      })
      removeDialog()
    })
  }

  const onCancel = () => {
    removeDialog()
  }

  return (
    <Dialog open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '32px' }}>Help Us Improve !</Typography>
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        <div className={classes.starContainer}>
          {[1, 2, 3, 4, 5].map((item) => {
            const isSelect = Rating && item <= Rating
            const icon = isSelect ? Images.startYellowActive : Images.startYellow
            return (
              <IconButton
                key={item}
                onClick={() => setState({ Rating: item })}
                classes={{ root: classes.btnContainer }}
              >
                <img src={icon} className={classes.iconStar} alt="" />
              </IconButton>
            )
          })}
        </div>
        <FormControl component="div" variant="standard" sx={{ width: '100%' }}>
          <FormLabel component="span" sx={{ color: '#333', marginBottom: '24px' }}>
            What areas could be improved?
          </FormLabel>
          <FormGroup>
            {[
              'Transcription Speed/Accuracy',
              'Note Accuracy',
              'Note Templates Enhancements',
              'Integration with Existing EHR',
              'New Language Support',
              'New Note Templates',
              'Other',
            ].map((item) => {
              const isSelect = Areas.includes(item)
              return (
                <FormControlLabel
                  key={item}
                  control={
                    <Checkbox
                      checked={isSelect}
                      onChange={() =>
                        setState({ Areas: isSelect ? Areas.filter((i) => i !== item) : [...Areas, item] })
                      }
                    />
                  }
                  label={item}
                  labelPlacement="start"
                  classes={{ label: classes.checkboxLabel }}
                  sx={{ marginLeft: 0 }}
                />
              )
            })}
          </FormGroup>
        </FormControl>

        <Typography className={classes.noteTemplate}>What areas could be improved?</Typography>
        <CustomTextField
          value={Comment}
          placeholder=""
          sx={{ width: '100%' }}
          multiline
          onInputChange={(value) => setState({ Comment: value })}
        />
      </Box>

      <Box className={classes.bottom}>
        <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSend}
          color="primary"
          className={classes.btn}
          disabled={!Rating || Areas.length === 0}
        >
          Send
        </Button>
      </Box>
    </Dialog>
  )
}

export default FeedbackDialog
