import axios, { AxiosInstance } from 'axios'
import Storages from 'utils/Storages'
import SnackBar from 'components/snackBar'

const ignoreUrl = ['/csm/Group/GetGroups']

function queryParams(params: any) {
  if (!params) return ''
  return Object.keys(params)
    .map((k) => {
      const value = params[k]
      const noValue = !value && value !== 0
      return `${k}=${noValue ? '' : value}`
    })
    .join('&')
}

class axiosService {
  baseUrl: string = ''
  instance: AxiosInstance

  constructor() {
    const instance = axios.create()
    instance.interceptors.request.use(
      async (config) => {
        const accessToken = await Storages.getAuthToken()
        const newConfig = Object.assign({}, config, {
          headers: {
            UserToken: accessToken || '',
          },
        })
        return newConfig
      },
      (error) => {
        Promise.reject(error)
      },
    )

    instance.interceptors.response.use(
      (response) => {
        // @ts-ignore
        response.duration = new Date().getTime()
        const errMsg = response.headers.error || response.headers.Error
        if (response.status === 200) {
          if (!errMsg) {
            return response.data.data
          }
          if (errMsg) {
            SnackBar({ msg: response.data })
            return Promise.reject(`Promise.reject: ${response.data}`)
          }
          const responseUrl = response?.config?.url as string
          SnackBar({ msg: response.data })
          return Promise.reject(response.data)
        }
      },
      async (error) => {
        const originalRequest = error.config
        return Promise.reject(error)
      },
    )
    this.instance = instance
  }

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  get<T>(url: string, data?: any): Promise<T> {
    let curUrl = url
    if (data) {
      curUrl = url + '?' + queryParams(data)
    }
    return this.instance.get(`${this.baseUrl}${curUrl}`) as Promise<T>
  }

  post<T>(url: string, body: any) {
    return this.instance.post(`${this.baseUrl}${url}`, body) as Promise<T>
  }

  patch<T>(url: string, body: any) {
    return this.instance.patch(`${this.baseUrl}${url}`, body) as Promise<T>
  }

  delete(url: string) {
    return this.instance.delete(`${this.baseUrl}${url}`)
  }
}

export default new axiosService()
