import { configureStore, ThunkAction, Action, combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import counterReducer from '../features/counter/counterSlice'
import transcriptSlice from './transcriptSlice'
import profileSlice from './profileSlice'
import Config from 'constants/Config'
const persistConfig = {
  key: 'pecker_root',
  storage: storage,
}
// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//     transcript: transcriptSlice,
//     profile: profileSlice,
//   },
// })
const reduces = {
  counter: counterReducer,
  transcript: transcriptSlice,
  profile: profileSlice,
}
const rootReducer = combineReducers(reduces)
type InferState<Type> = Type extends Reducer<infer S> ? S : never

type State<T extends typeof reduces = typeof reduces, P extends keyof T = keyof T> = {
  [K in P]: InferState<T[K]>
}

// @ts-ignore
const myPersistReducer: Reducer<State, AnyAction> = persistReducer<State, AnyAction>(persistConfig, rootReducer)

export const store = configureStore({
  reducer: myPersistReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: false,
    })
    if (Config.env !== 'PROD') {
      //@ts-ignore
      middleware.push(logger)
    }
    console.log('Config.env', Config.env, middleware)
    return middleware
  },
  devTools: true,
})
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
