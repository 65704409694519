import React, { useCallback, useState } from 'react'
import { Box, Button, Dialog as DialogUi, IconButton, Typography, Drawer } from '@mui/material'
import classes from './RegenerateDialog.module.less'
import { Close } from '@mui/icons-material'
import CustomSelect, { CustomSelectChoice } from '../common/CustomSelect'
import useGetNoteTemplates from 'hooks/useGetNoteTemplate'

interface Props {
  onClose: () => void
  noteTemplate?: string
  onRegenerate?: (template: string) => void
}

const RegenerateDialog: React.FC<Props> = ({ onClose, noteTemplate, onRegenerate }) => {
  const [data, setData] = useState(noteTemplate)
  const [open, setOpen] = useState(true)
  const { template } = useGetNoteTemplates()

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onRegenerateCall = () => {
    onCancel()
    onRegenerate?.(data as string)
  }

  const onCancel = () => {
    removeDialog()
  }
  const onChangeNoteTemplate = useCallback((value: CustomSelectChoice | null) => {
    value && setData(value.value as string)
  }, [])
  return (
    <DialogUi open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      <Typography className={classes.title}>Regenerate Note</Typography>
      <Typography className={classes.desc}>This operation will erase your current notes. Continue?</Typography>
      <Typography className={classes.noteTemplate}>Note template</Typography>
      <CustomSelect
        label=""
        sx={{ width: '100%' }}
        value={template?.find((item1: CustomSelectChoice) => item1.value === data)}
        onChange={onChangeNoteTemplate}
        choices={template || []}
      />
      <Box className={classes.bottom}>
        <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onRegenerateCall} color="primary" className={classes.btn} disabled={!data}>
          Regenerate
        </Button>
      </Box>
    </DialogUi>
  )
}

export const H5RegenerateDialog: React.FC<Props> = ({ onClose, noteTemplate, onRegenerate }) => {
  const [data, setData] = useState(noteTemplate)
  const [open, setOpen] = useState(true)
  const { template } = useGetNoteTemplates()

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onRegenerateCall = () => {
    onCancel()
    onRegenerate?.(data as string)
  }

  const onCancel = () => {
    removeDialog()
  }
  const onChangeNoteTemplate = useCallback((value: CustomSelectChoice | null) => {
    value && setData(value.value as string)
  }, [])
  return (
    <Drawer anchor="bottom" open={open} onClose={removeDialog} classes={{ paper: classes.h5container }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className={classes.title} style={{ fontSize: 24 }}>
          Regenerate Note
        </Typography>
        <Close htmlColor="#211F1F" sx={{ fontSize: 30 }} onClick={onCancel} />
      </Box>
      <Typography className={classes.h5desc}>This operation will erase your current notes. Continue?</Typography>
      <Typography className={classes.noteTemplate}>Note template</Typography>
      <CustomSelect
        label=""
        sx={{ width: '100%' }}
        value={template?.find((item1: CustomSelectChoice) => item1.value === data)}
        onChange={onChangeNoteTemplate}
        choices={template || []}
      />
      <Box className={classes.bottom}>
        <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onRegenerateCall} color="primary" className={classes.btn} disabled={!data}>
          Regenerate
        </Button>
      </Box>
    </Drawer>
  )
}

export default RegenerateDialog
