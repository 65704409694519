import { useMemo, useEffect } from 'react'
import { Outlet, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import Images from 'assets'
import { Box, Button, Typography } from '@mui/material'
import classNames from 'classnames'
import { usePermissions, useSetState } from 'hooks'
import { ExpandMore, ExpandLess, GppGood } from '@mui/icons-material'
import styles from './Layout.module.less'

import Storages from 'utils/Storages'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectProfile } from 'app/profileSlice'
import ContactUsDialog from 'components/modal/ContactUsDialog'
import HIPAADialog from 'components/modal/HIPAA'
import UpdateDialog from 'components/modal/UpdateDialog'
const IGNORE_ROUTER = ['/login', '/resetPassword']

export default function Layout() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectProfile)
  const { user } = profile
  const Status = user?.Status || ''
  const { pathname } = useLocation()
  const [search] = useSearchParams()
  const su = useMemo(() => search.get('su'), [search])

  console.log('sususu', su)
  const PermissionsHash = usePermissions()
  const MFAEnabled = !!user?.MFAEnabled
  const [{ open, loading, showContact, showHipaa, showUpdate }, setState] = useSetState({
    open: [] as string[],
    loading: true,
    showContact: false,
    showHipaa: false,
    showUpdate: !user?.AgreementAcceptedV2,
  })
  const menu: Record<string, any>[] = useMemo(() => {
    const { AdminLogin } = PermissionsHash
    return [
      { name: 'Home', path: '/', icon: Images.home },
      { name: 'Consultations', path: '/history', childrenPaths: ['/history/'], icon: Images.history },
      {
        name: 'Admin',
        icon: Images.admin,
        hidden: !AdminLogin,
        child: [
          { name: 'Dashboard', path: '/adminDashboard' },
          // { name: 'Consultations', path: '/adminConsultations' },
          { name: 'Outside Sales', path: '/outsideSales' },
        ],
      },
      { name: 'Settings', path: '/settings', icon: Images.settings },
      { name: 'Contact us', path: 'contact', icon: Images.contact },
    ]
  }, [PermissionsHash])

  const onClick = (item: Record<string, any>) => {
    const { name, path, child } = item
    if (path === 'contact') {
      return setState({ showContact: true })
    }
    if (child) {
      const isOpen = open.includes(name)
      if (isOpen) {
        return setState({ open: open.filter((item) => item !== name) })
      }
      setState({ open: [...open, name] })
    }
    if (!path) return
    navigate(`${path}`, { replace: true })
  }
  const openHipaa = () => {
    setState({ showHipaa: true })
  }

  useEffect(() => {
    const token = Storages.getAuthToken()
    if (!token || !['Registered', 'Verified'].includes(Status)) {
      Storages.removeAllDate()
      if (IGNORE_ROUTER.includes(pathname)) return
      navigate('/signUp', { replace: true, state: { su } })
    }
    setState({ loading: false })
  }, [dispatch, navigate, Status, pathname, setState])
  const onClose = () => {
    setState({ showContact: false })
  }
  if (loading) return null

  return (
    <Box className={styles.layout}>
      {showContact && <ContactUsDialog onClose={onClose} />}
      <Box className={styles.left}>
        <Typography className={styles.title}>
          <img src={Images.logo} alt="logo" className={styles.logo} />
          Woodpecker Copilot
        </Typography>
        <Box className={styles.menu}>
          {menu.map((item) => {
            const { childrenPaths, path, name, icon, hidden = false, child } = item
            const isChildrenPaths =
              childrenPaths && !!childrenPaths.find((item: string) => location.pathname.includes(item))
            const isActive = location.pathname === path || isChildrenPaths
            if (hidden) return null
            if (child) {
              const isOpen = open.includes(name)
              return (
                <>
                  <Button onClick={() => onClick(item)} className={styles.menuItem}>
                    <img src={icon} alt={icon} className={styles.icon} />
                    <span style={{ flex: 1 }}>{name}</span>
                    {isOpen ? (
                      <ExpandLess sx={{ fontSize: '24px' }} />
                    ) : (
                      <ExpandMore fontSize="large" sx={{ fontSize: '24px' }} />
                    )}
                  </Button>
                  {child.map((item: Record<string, any>) => {
                    const { path, name, hidden = false } = item
                    if (hidden || !isOpen) return null
                    return (
                      <Button
                        onClick={() => onClick(item)}
                        className={classNames(styles.menuItem, { [styles.active]: location.pathname === path })}
                        sx={{ marginLeft: '40px' }}
                        key={name}
                      >
                        <span>{name}</span>
                      </Button>
                    )
                  })}
                </>
              )
            }
            return (
              <Button
                onClick={() => onClick(item)}
                className={classNames(styles.menuItem, { [styles.active]: isActive })}
                key={name}
              >
                <img src={icon} alt={icon} className={styles.icon} />
                <span>{name}</span>
              </Button>
            )
          })}
        </Box>
        {/* {MFAEnabled && (
          <Box className={styles.safety}>
            <GppGood htmlColor="#178204" sx={{ marginRight: '8px' }} />
            Safety advice
          </Box>
        )} */}
        <Box className={styles.hipaa} onClick={openHipaa}>
          <img src={Images.HIPAA} alt="" style={{ marginRight: '8px' }} />
          We are HIPAA Compliant!
        </Box>
        {showHipaa && <HIPAADialog onClose={() => setState({ showHipaa: false })} />}
        {showUpdate && <UpdateDialog onClose={() => setState({ showUpdate: false })} />}
      </Box>
      <Outlet />
    </Box>
  )
}
