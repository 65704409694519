import { useEffect, useCallback, useRef, useMemo } from 'react'
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material'
import { Pagination, PaginationItem } from '@mui/material'
import { useSetState, useGetNoteTemplates } from 'hooks'
import classes from './AdminConsultations.module.less'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import PeriodSelect from 'components/common/PeriodSelect'
import CustomTextField from 'components/common/CustomTextField'
import CustomSelect, { CustomSelectChoice } from 'components/common/CustomSelect'
import { formatTime, PERIOD_OPTIONS } from 'utils/common'
import { getAllConsultations, submitExportTask, getExportList, downloadExportZip } from 'apis'
import { format } from 'date-fns'
import ConsultationsDialog from 'components/modal/ConsultationsDialog'
import SwitchTab from 'components/common/SwitchTab'
import { SaveAlt, Close, SkipPrevious, SkipNext } from '@mui/icons-material'
import Images from 'assets'
import ExportDetail from 'components/modal/ExportDetail'
import { useLocation } from 'react-router-dom'

const TABS = [
  {
    label: 'Table of Consultations',
  },
  {
    label: 'Export History',
  },
]
const payload = {
  Doctor: 'doctor',
  Invitor: 'invitor',
  Diseases: 'diseases',
  Medicines: 'medicines',
  Customer: 'customer',
  User: 'user',
  FriendlyId: 'id',
}

const PAGE_SIZE = 100

const columns: GridColDef[] = [
  {
    field: 'FriendlyId',
    headerName: 'ID',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 80,
  },
  {
    field: 'CreatedDate',
    headerName: 'Date',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 200,
    valueGetter: ({ row }) => format(new Date(row.CreatedDate), 'MM/dd/yyyy HH:mm a'),
  },
  {
    field: 'DoctorName',
    headerName: 'Doctor Name',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 180,
  },
  {
    field: 'InvitorName',
    headerName: 'Outside Sales',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 180,
  },
  {
    field: 'Title',
    headerName: 'Title',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 300,
  },
  {
    field: 'Duration',
    headerName: 'Duration',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 120,
    valueGetter: ({ row }) => formatTime(row.Duration),
  },
  {
    field: 'NoteTemplate',
    headerName: 'Note Template',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 280,
  },
  {
    field: 'Source',
    headerName: 'Source',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 120,
  },
  {
    field: 'Status',
    headerName: 'Status',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 120,
  },
  {
    field: 'Diseases',
    headerName: 'Diseases/Conditions',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 300,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Disease?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
  {
    field: 'Medicine',
    headerName: 'Medications/Procedures',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 300,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Medicine?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
]

const historyColumns: GridColDef[] = [
  {
    field: 'CustomerName',
    headerName: 'Customer Name',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'ExportDate',
    headerName: 'Export Date',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 220,
    valueGetter: ({ row }) => format(new Date(row.ExportDate || null), 'MM/dd/yyyy HH:mm a'),
  },
  {
    field: 'FullName',
    headerName: 'User',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Status',
    headerName: 'Status',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 200,
  },
  {
    field: 'Details',
    headerName: 'Details',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 200,
    renderCell: ({ row }) => {
      if (['Pending', 'Failed'].includes(row.Status)) return ''
      return <Button variant="outlined">View</Button>
    },
  },
  {
    field: 'ZipFileUrl',
    headerName: 'Download URL',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 200,
    renderCell: ({ row }) => {
      if (row.Status !== 'Active') return ''
      return (
        <Button variant="outlined">
          <SaveAlt fontSize="small" sx={{ marginRight: '8px' }} />
          Download
        </Button>
      )
    },
  },
]

const STATUS_OPTION = ['Pending', 'Failed', 'Active', 'Downloaded', 'Expired'].map((v) => ({ label: v, value: v }))
const CONSULTATION_STATUS = ['Pending', 'Active', 'Archived', 'Aborted'].map((v) => ({ label: v, value: v }))

const AdminConsultations = () => {
  const { template } = useGetNoteTemplates()
  const location = useLocation()
  const { invitorId } = useMemo(() => location.state || {}, [location.state])
  const [state, setState] = useSetState({
    page: 0,
    pageSize: PAGE_SIZE,
    period: PERIOD_OPTIONS.find((item) => item.value === '7-Day'),
    render: false,
    rows: [] as Record<string, any>[],
    loading: true,
    Doctor: '',
    Invitor: '',
    NoteTemplate: '',
    showModal: false,
    ConsultationId: '',
    rowCount: 0,
    doctor: '',
    invitor: '',
    tabIdx: 0,
    Diseases: '',
    diseases: '',
    Medicines: '',
    medicines: '',
    Customer: '',
    customer: '',
    User: '',
    user: '',
    Status: '',
    pageHistory: 0,
    pageSizeHistory: PAGE_SIZE,
    ExportCustomer: '',
    Note: '',
    ExportModal: false,
    periodHistory: PERIOD_OPTIONS.find((item) => item.value === '7-Day'),
    renderHistory: false,
    showDetails: false,
    jump: '',
    cStatus: '',
    FriendlyId: '',
    id: '',
  })
  const { period, rows, loading, Doctor, NoteTemplate, showModal, ConsultationId, rowCount, doctor, invitor, Invitor } =
    state
  const { Diseases, Medicines, Customer, User, user, Status, diseases, medicines, customer, cStatus } = state
  const { page, pageSize, tabIdx, render, ExportCustomer, Note, ExportModal, periodHistory } = state
  const { pageHistory, pageSizeHistory, renderHistory, showDetails, jump, FriendlyId, id } = state
  const tu = period?.value
  const tuHistory = periodHistory?.value
  const timerRef = useRef<NodeJS.Timeout>()
  const jumpRef = useRef<NodeJS.Timeout>()
  const dataRef = useRef({
    selection: [] as string[],
    historyList: [] as Record<string, any>[],
    historyCount: 0,
    historyRow: {} as Record<string, any>,
  })
  const { selection, historyList, historyCount, historyRow } = dataRef.current

  const onInputChange = useCallback((value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
    if (['ExportCustomer', 'Note'].includes(name)) return
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      // @ts-ignore
      setState({ [payload[name]]: value, page: 0, pageHistory: 0 })
    }, 3000)
  }, [])

  const onChangeNoteTemplate = useCallback((value: CustomSelectChoice | null) => {
    value && setState({ NoteTemplate: value.value as string, page: 0 })
  }, [])

  const onExport = () => {
    submitExportTask({ CustomerName: ExportCustomer, Note, ConsultationIds: selection }).then(() => {
      setState({ ExportModal: false, tabIdx: 1 })
    })
  }

  const onJump = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
    if (jumpRef.current) {
      clearTimeout(jumpRef.current)
    }
    jumpRef.current = setTimeout(() => {
      // @ts-ignore
      setState({ page: Math.max(Number(value) - 1, 0) })
    }, 3000)
  }

  useEffect(() => {
    if (tabIdx !== 0) return
    setState({ loading: true })
    getAllConsultations({
      tu,
      doctor,
      invitor,
      nt: NoteTemplate,
      dse: diseases,
      mde: medicines,
      skip: page * PAGE_SIZE,
      take: PAGE_SIZE,
      status: cStatus,
      id,
      invitorId,
    })
      .then((res: any) => {
        const rows = res?.Consultations || []
        setState((pre) => ({ ...pre, page, rows, rowCount: res?.Total || 0 }))
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [invitorId, tu, NoteTemplate, doctor, invitor, diseases, medicines, page, tabIdx, id, cStatus])

  useEffect(() => {
    if (tabIdx !== 1) return
    setState({ loading: true })
    getExportList({ tu: tuHistory, customer, user, status: Status, skip: pageHistory * PAGE_SIZE, take: PAGE_SIZE })
      .then((res: any) => {
        dataRef.current.historyList = res?.ConsultationExports || []
        dataRef.current.historyCount = res?.Total || 0
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [tuHistory, customer, user, Status, pageHistory, tabIdx, renderHistory])

  return (
    <Box className={classes.layout}>
      <Box className={classes.header}>
        <span className={classes.headerText} style={{ flex: 1 }}>
          Consultations
        </span>
        <SwitchTab tabs={TABS} onChange={(tabIdx) => setState({ tabIdx })} value={tabIdx} />
      </Box>

      <Box className={classes.body}>
        <Box className={classes.table}>
          <Box style={{ minWidth: '1700px', display: tabIdx !== 0 ? 'none' : '' }}>
            <Box className={classes.filter}>
              <Typography className={classes.tableTitle}>Table of Consultations</Typography>
              <Button
                variant="contained"
                className={classes.export}
                disabled={selection.length === 0}
                onClick={() => setState({ ExportModal: true, ExportCustomer: '', Note: '' })}
              >
                <img src={Images.export} alt="" style={{ marginRight: 8 }} /> Export
              </Button>
              <PeriodSelect
                period={period}
                onChange={(value) => setState({ period: value, page: 0 })}
                label="Date Range"
                className={classes.inputRoot}
                style={{ width: '160px', marginLeft: '16px' }}
              />
              <CustomTextField
                name="Doctor"
                label="Doctor"
                value={Doctor}
                rootClassName={classes.inputRoot}
                onInputChange={onInputChange}
                sx={{ marginLeft: '16px' }}
              />
              <CustomSelect
                label="Note Template"
                sx={{ width: '180px', margin: '0 0 0 16px' }}
                value={template?.find((item1: CustomSelectChoice) => item1.value === NoteTemplate)}
                onChange={onChangeNoteTemplate}
                choices={template || []}
              />
              <CustomTextField
                name="Invitor"
                label="Outside Sales"
                value={Invitor}
                rootClassName={classes.inputRoot}
                onInputChange={onInputChange}
                sx={{ marginLeft: '16px' }}
              />
              <CustomTextField
                name="FriendlyId"
                label="ID"
                value={FriendlyId}
                rootClassName={classes.inputRoot}
                onInputChange={onInputChange}
                sx={{ marginLeft: '16px' }}
              />
              <CustomSelect
                label="Status"
                sx={{ width: '180px', margin: '0 0 0 16px' }}
                value={CONSULTATION_STATUS?.find((item1: CustomSelectChoice) => item1.value === cStatus)}
                onChange={(value) => setState({ cStatus: value?.value as string, page: 0 })}
                choices={CONSULTATION_STATUS || []}
              />
              <CustomTextField
                name="Diseases"
                label="Diseases"
                value={Diseases}
                rootClassName={classes.inputRoot}
                onInputChange={onInputChange}
                sx={{ marginLeft: '16px' }}
              />
              <CustomTextField
                name="Medicines"
                label="Medicines"
                value={Medicines}
                rootClassName={classes.inputRoot}
                onInputChange={onInputChange}
                sx={{ marginLeft: '16px' }}
              />
            </Box>
            <DataGrid
              rows={rows}
              rowCount={rowCount}
              columns={columns}
              checkboxSelection
              disableRowSelectionOnClick
              isRowSelectable={({ row }) => (row.NoExport || row.DupeTranscript ? false : true)}
              getRowHeight={() => 'auto'}
              pagination
              loading={loading}
              localeText={{ noRowsLabel: 'No Data' }}
              onRowClick={({ row }) => {
                setState({ showModal: true, ConsultationId: row.ConsultationId })
              }}
              onRowSelectionModelChange={(selection) => {
                dataRef.current.selection = selection as string[]
                setState({ render: !render })
              }}
              components={{
                Pagination: () => {
                  return (
                    <>
                      <Pagination
                        className={classes.pagination}
                        color="primary"
                        count={Math.ceil(rowCount / PAGE_SIZE)}
                        page={page + 1}
                        showFirstButton
                        showLastButton
                        onChange={(event, page) => {
                          setState({ page: page - 1 })
                        }}
                        style={{ justifyContent: 'flex-end' }}
                        renderItem={(item) => (
                          <PaginationItem slots={{ first: SkipPrevious, last: SkipNext }} {...item} />
                        )}
                      />
                      <Box sx={{ flex: 1 }}>
                        Jump To
                        <input
                          type="number"
                          value={jump}
                          onChange={(e) => onJump(e.target.value, 'jump')}
                          style={{ width: '60px', margin: '0 8px', textAlign: 'center' }}
                        />
                        Page
                      </Box>
                    </>
                  )
                },
              }}
            />
          </Box>

          <Box style={{ minWidth: '1200px', display: tabIdx !== 1 ? 'none' : '' }}>
            <Box className={classes.filter}>
              <Typography className={classes.tableTitle}>Export History</Typography>
              <CustomTextField
                name="Customer"
                label="Customer"
                value={Customer}
                rootClassName={classes.inputRoot}
                onInputChange={onInputChange}
                sx={{ marginLeft: '16px' }}
              />
              <CustomTextField
                name="User"
                label="User"
                value={User}
                rootClassName={classes.inputRoot}
                onInputChange={onInputChange}
                sx={{ margin: '0 0 0 16px' }}
              />
              <PeriodSelect
                period={periodHistory}
                onChange={(value) => setState({ periodHistory: value, pageHistory: 0 })}
                label="Date Range"
                className={classes.inputRoot}
                style={{ width: '180px', marginLeft: '16px' }}
              />
              <CustomSelect
                label="Status"
                sx={{ width: '180px', margin: '0 0 0 16px' }}
                value={STATUS_OPTION?.find((item1: CustomSelectChoice) => item1.value === Status)}
                onChange={(value) => setState({ Status: value?.value as string, pageHistory: 0 })}
                choices={STATUS_OPTION}
              />
            </Box>
            <DataGrid
              rows={historyList}
              rowCount={historyCount}
              columns={historyColumns}
              rowSelection={false}
              classes={{
                virtualScroller: classes.virtualScroller,
                row: classes.row,
                cell: classes.cell,
                columnHeader: classes.columnHeader,
                columnSeparator: classes.columnSeparator,
              }}
              loading={loading}
              localeText={{ noRowsLabel: 'No Data' }}
              onCellClick={({ field, row }) => {
                if (field === 'ZipFileUrl' && row.Status === 'Active') {
                  window.open(row.ZipFileUrl, '_blank')
                  downloadExportZip({ ConsultationExportId: row.ConsultationExportId }).then(() => {
                    setState({ renderHistory: !renderHistory })
                  })
                }
                if (field === 'Details' && !['Pending', 'Failed'].includes(row.Status)) {
                  dataRef.current.historyRow = row
                  setState({ showDetails: true })
                }
              }}
              pagination
              components={{
                Pagination: () => {
                  return (
                    <Pagination
                      className={classes.pagination}
                      color="primary"
                      count={Math.ceil(historyCount / PAGE_SIZE)}
                      page={pageHistory + 1}
                      showFirstButton
                      showLastButton
                      onChange={(event, page) => {
                        setState({ pageHistory: page - 1 })
                      }}
                    />
                  )
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {showModal && (
        <ConsultationsDialog onClose={() => setState({ showModal: false })} ConsultationId={ConsultationId} />
      )}

      <Dialog open={ExportModal}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', padding: '24px' }}>
          <Typography sx={{ flex: 1, color: '#333', fontWeight: '700', fontSize: '32px' }}>Export</Typography>
          <IconButton onClick={() => setState({ ExportModal: false })}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: '#333', fontSize: '20px' }}>
            Please fill in the customer name and notes to export
          </Typography>
          <Typography sx={{ color: '#8c8c8c', fontSize: '16px', margin: '16px 0 8px' }}>Customer Name</Typography>
          <CustomTextField
            name="ExportCustomer"
            label=""
            value={ExportCustomer}
            onInputChange={onInputChange}
            fullWidth
          />
          <Typography sx={{ color: '#8c8c8c', fontSize: '16px', margin: '16px 0 8px' }}>Note</Typography>
          <CustomTextField multiline name="Note" label="" value={Note} onInputChange={onInputChange} fullWidth />
        </DialogContent>
        <DialogActions sx={{ padding: '24px' }}>
          <Button variant="outlined" onClick={() => setState({ ExportModal: false })} sx={{ flex: 1, height: '50px' }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onExport}
            sx={{ flex: 1, height: '50px' }}
            disabled={!ExportCustomer || !Note}
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>

      {showDetails && (
        <ExportDetail onClose={() => setState({ showDetails: false })} eId={historyRow?.ConsultationExportId} />
      )}
    </Box>
  )
}
export default AdminConsultations
