import React from 'react'
import { Snackbar as SnackBarMui, Alert, AlertColor } from '@mui/material'
import { createRoot } from 'react-dom/client'

export default function SnackBar({
  msg = '',
  duration = 2000,
  type = 'error',
}: {
  msg: string | any
  duration?: number
  type?: AlertColor
}) {
  const message = msg.message || msg
  if (message === '') return
  const open = true
  const div = document.createElement('div')
  const root = document.getElementById('root')
  root?.appendChild(div)

  createRoot(div).render(
    <SnackBarMui open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={duration}>
      <Alert severity={type} classes={{ icon: 'snackBarIcon' }}>
        {message}
      </Alert>
    </SnackBarMui>,
  )
  const removeSnackBar = () => {
    root?.removeChild(div)
  }
  setTimeout(removeSnackBar, duration)
}
