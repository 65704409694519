import { Box, Typography } from '@mui/material'
import React, { useLayoutEffect, useRef, useState } from 'react'
import classes from './AutoTitleTextField.module.less'
import Images from 'assets'
import { useSetState } from 'hooks'

interface Props {
  value?: string
  onTitleChange?: (title: string) => void
  onUpdate: (title: string) => void
}

const AutoTitleTextField: React.FC<Props> = ({ value, onTitleChange, onUpdate }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [{ inputValue, isEdit }, setState] = useSetState({ inputValue: value, isEdit: false })

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ inputValue: event.target.value })
  }
  const onMouseEnter = () => {
    setState({ inputValue: value, isEdit: true })
  }
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (inputValue) {
        onUpdate?.(inputValue)
        setState({ isEdit: false })
      }
    }
  }
  const onClickUpdate = () => {
    //onMouseDown 解决长按触发不了onClick事件
    if (inputValue) {
      onUpdate?.(inputValue)
      setState({ isEdit: false })
    }
  }
  const onBlur = () => {
    const timer = setTimeout(() => {
      setState({ isEdit: false })
      clearTimeout(timer)
    }, 100)
  }
  return (
    <Box className={classes.titleContainer} onClick={onMouseEnter}>
      {!isEdit ? (
        <Typography className={classes.titleText}>{value}</Typography>
      ) : (
        <input
          ref={inputRef}
          autoFocus
          defaultValue={value}
          className={classes.title}
          onChange={handleInput}
          onKeyDown={onKeyDown}
          value={inputValue}
          onBlur={onBlur}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      {isEdit ? (
        <Box onMouseDown={onClickUpdate}>
          <img src={Images.checked} className={classes.edit} alt="" onContextMenu={() => console.log(888)} />
        </Box>
      ) : (
        <img src={Images.edit} className={classes.edit} alt="" />
      )}
    </Box>
  )
}

export default AutoTitleTextField
