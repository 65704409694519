import React, { useEffect } from 'react'
import Note from './Note'
import { IConsultation, ISmartNote } from 'types/transcription'
import classes from './SmartNote.module.less'
import Markdown from 'react-markdown'
import { Box, Button, Typography } from '@mui/material'
import { useAppDispatch } from 'app/hooks'
import { actions } from 'app/profileSlice'
import { useSetState } from 'hooks'
import Images from 'assets'
import RegenerateDialog, { H5RegenerateDialog } from 'components/modal/RegenerateDialog'
import { generateSmartNote } from 'apis'
import SnackBar from '../snackBar'
import classnames from 'classnames'

interface Props {
  smartNotes?: ISmartNote[]
  smartNote?: string
  cId?: string
  noteTemplate?: string
  onRegenerateSuccess?: (smartNote: string, noteTemplate: string) => void
  isMobile?: boolean
}

const SmartNote: React.FC<Props> = ({ smartNotes, smartNote, cId, onRegenerateSuccess, noteTemplate, isMobile }) => {
  const dispatch = useAppDispatch()

  const [data, setData] = useSetState({
    NoteTemplate: noteTemplate,
    ShowDialog: false,
    Generating: false,
  })
  const { NoteTemplate, ShowDialog, Generating } = data || {}
  const onClose = () => {
    setData({ ShowDialog: false })
  }

  const onRegenerateDialog = () => {
    setData({ ShowDialog: true })
  }
  const onRegenerate = (NoteTemplate: string) => {
    setData({ Generating: true })
    generateSmartNote<{ Consultation: IConsultation }>({ ConsultationId: cId, NoteTemplate })
      .then((res) => {
        dispatch(actions.updateNoteTemplate(NoteTemplate))
        setData({ Generating: false, NoteTemplate })
        onRegenerateSuccess?.(res.Consultation?.SmartNoteText, NoteTemplate)
        SnackBar({ msg: 'Successfully Regenerate', type: 'success' })
      })
      .finally(() => {
        setData({ Generating: false })
      })
  }
  if (smartNote) {
    return (
      <Box className={classes.container}>
        {ShowDialog ? (
          isMobile ? (
            <H5RegenerateDialog onClose={onClose} noteTemplate={NoteTemplate} onRegenerate={onRegenerate} />
          ) : (
            <RegenerateDialog onClose={onClose} noteTemplate={NoteTemplate} onRegenerate={onRegenerate} />
          )
        ) : null}
        <Box className={classnames(classes.wrap, { [classes.h5wrap]: isMobile })} onClick={onRegenerateDialog}>
          <Typography
            className={classes.noteTip}
            sx={{ fontSize: isMobile ? '12px' : '18px' }}
            onClick={onRegenerateDialog}
          >{`Note generated with the “${NoteTemplate || 'N/A'}” template`}</Typography>
          <Button
            className={classnames(classes.regenerate, { [classes.h5regenerate]: isMobile })}
            disabled={Generating}
          >
            {isMobile ? '' : 'Regenerate Note'}
            <img
              src={Images.refresh}
              className={classnames(classes.refresh, {
                [classes.refreshLoading]: Generating,
                [classes.h5refresh]: isMobile,
              })}
              alt=""
            />
          </Button>
        </Box>
        <div id="markdown">
          <Markdown
            className={classnames(classes.text, {
              [classes.h5text]: isMobile,
            })}
          >
            {smartNote}
          </Markdown>
        </div>
      </Box>
    )
  }
  return (
    <>
      {smartNotes?.map((item, index) => {
        return <Note key={item.Name + index} smartNote={item} />
      })}
    </>
  )
}

export default SmartNote
