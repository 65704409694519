import React, { useState } from 'react'
import { Box, Dialog as DialogUi, Typography } from '@mui/material'
import classes from './HIPAADialog.module.less'
import { Close } from '@mui/icons-material'

interface Props {
  onClose: () => void
}

const HIPAADialog: React.FC<Props> = ({ onClose }) => {
  const [open, setOpen] = useState(true)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }

  return (
    <DialogUi open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      <iframe
        src="https://hipaamate.com/hipaa-certificate/l70r6j8dln2cnz3yd446fkak"
        title="HIPAA"
        width="100%"
        height="500px"
        className={classes.iframe}
      />
    </DialogUi>
  )
}

export const H5HIPAADialog: React.FC<Props> = ({ onClose }) => {
  const [open, setOpen] = useState(true)

  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }

  return (
    <DialogUi open={open} onClose={removeDialog} classes={{ paper: classes.h5container }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Typography className={classes.title}>HIPAA</Typography>
        <Close htmlColor="#211F1F" sx={{ fontSize: 30 }} onClick={removeDialog} />
      </Box>
      <iframe
        src="https://hipaamate.com/hipaa-certificate/l70r6j8dln2cnz3yd446fkak"
        title="HIPAA"
        width="100%"
        height="500px"
        className={classes.iframe}
      />
    </DialogUi>
  )
}

export default HIPAADialog
