import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { ITranscription } from 'types/transcription'
import { format } from 'date-fns'
import classes from './TranscriptionItem.module.less'

interface Props {
  item: ITranscription
  isTranscribing?: boolean
}
const TranscriptionItem: React.FC<Props> = ({ item, isTranscribing }) => {
  const [tip, setTip] = useState(0)
  useEffect(() => {
    if (!isTranscribing) return
    const inter = setInterval(() => {
      setTip((prevState) => {
        if (prevState === 3) {
          return 0
        }
        return prevState + 1
      })
    }, 300)
    return () => {
      clearInterval(inter)
    }
  }, [isTranscribing])
  return (
    <Box className={classes.container}>
      <Typography className={classes.time}>{format(Number(item.Time), 'pp')}</Typography>
      <Typography className={classes.caption}>
        {item.Caption}
        {isTranscribing ? new Array(tip).fill(' .').join('') : ' '}
      </Typography>
    </Box>
  )
}

export const TranscriptionH5Item: React.FC<Props> = ({ item }) => {
  return (
    <Box className={classes.container}>
      <Typography className={classes.h5time}>{format(Number(item.Time), 'pp')}</Typography>
      <Typography className={classes.h5caption}>{item.Caption}</Typography>
    </Box>
  )
}
export default TranscriptionItem
