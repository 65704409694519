// 创建一个对象来存储所有的图标
const Icons: Record<string, string> = {}
// 创建一个上下文，包含了 `./icons` 目录下的所有 `.svg` 文件
//@ts-ignore
const iconsContext = require.context('./icons', false, /\.(svg|gif)$/)

// 遍历上下文中的每个模块
iconsContext.keys().forEach((key: string) => {
  if (key.includes('assets/icons/')) return
  // 从模块路径中提取图标的名称，例如：从 "./password.svg" 提取 "password"
  const iconName = key.replace(/^\.\/(.*)\.(svg|gif)$/, '$1')
  // 将图标存储在 Icons 对象中
  Icons[iconName] = iconsContext(key)
})

const Images = { ...Icons }
export default Images
