import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserITF } from 'types/user'
import { RootState } from './store'

export interface RootUser {
  isRegistered?: boolean
  user?: UserITF | null
}

const INITIAL_STATE: RootUser = {}

const profileSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    loginSuccess: (state, action: PayloadAction<UserITF>) => ({
      ...state,
      user: action.payload,
      isRegistered: action.payload.Status === 'Registered',
    }),
    updateNoteTemplate: (state, action: PayloadAction<string>) => ({
      ...state,
      user: Object.assign({}, state.user, { NoteTemplate: action.payload }),
    }),
    loginOut: (state) => {
      return {
        user: null,
        isRegistered: false,
      }
    },
    updateUser: (state, action: PayloadAction<Record<string, any>>) => ({
      ...state,
      user: Object.assign({}, state.user, action.payload),
    }),
  },
})
export const selectProfile = (state: RootState) => state.profile

const { actions } = profileSlice
export { actions }

export default profileSlice.reducer
