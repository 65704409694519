import SnackBar from '../components/snackBar'

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      resolve(e.target?.result)
    }
    // readAsDataURL
    fileReader.readAsDataURL(blob)
    fileReader.onerror = () => {
      reject(new Error('blobToBase64 error'))
    }
  })
}
export const formatPhoneNumber = (str: string) => {
  const cleaned = ('' + str).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    // const intlCode = (match[1] ? '+1 ' : '')
    return ['', '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return str
}
export const isValidPassword = (password: string) => {
  // 密码必须至少包含 8 个字符，其中至少有 1 个大写字母、1 个数字
  const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d~!@#$%^*-_+=\\[{\]}/;:,.?]{8,}$/
  return { checked: regex.test(password), message: '8 characters with at least 1 upper case, 1 number.' }
}

export const isValidEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(email)
}

export const copyText = (clipText: string) => {
  const promise = navigator.clipboard.writeText(clipText)
  SnackBar({ msg: 'Copy Successfully', type: 'success' })
}

export const copyElementContent = async (id: string) => {
  const element = document.getElementById(id)
  if (!element) return
  const range = document.createRange()
  const selection = window.getSelection()
  if (!selection) return

  // 清空选区
  selection.removeAllRanges()
  // 保存原来的背景色
  const originalBackgroundColor = element.style.backgroundColor
  // 设置背景色为透明
  element.style.backgroundColor = '#fff'
  range.selectNode(element)

  // 添加进选区
  selection.addRange(range)

  // 拷贝
  document.execCommand('copy')

  // 恢复原来的背景色
  element.style.backgroundColor = originalBackgroundColor
  selection.removeAllRanges()
  SnackBar({ msg: 'Copy Successfully', type: 'success' })
}

export const PERIOD_OPTIONS: IOption[] = [
  { label: 'Today', value: 'Today' },
  { label: 'WTD', value: 'WTD' },
  { label: 'MTD', value: 'MTD' },
  { label: 'Last 7 Days', value: '7-Day' },
  { label: 'Last 30 Days', value: '30-Day' },
  { label: 'Last 60 Days', value: '60-Day' },
  { label: 'Last 90 Days', value: '90-Day' },
  { label: 'Last 12 Months', value: '1-Year' },
  { label: 'YTD', value: 'YearToDate' },
  { label: 'Max', value: 'ALL' },
]
export function formatTime(seconds: number = 0) {
  if (seconds >= 3600) {
    return (seconds / 3600).toFixed(2) + ' Hours'
  } else {
    return (seconds / 60).toFixed(2) + ' min'
  }
}
declare global {
  interface IOption {
    label: string
    value: string | number
    child?: IOption[]
    [key: string]: any
  }
}

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|ZoomDesktop|ZoomApp/i.test(
  navigator.userAgent,
)
