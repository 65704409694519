import TextField from '@mui/material/TextField'
import React, { useCallback } from 'react'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import classnames from 'classnames'
import classes from './CustomTextField.module.less'

export interface Props {
  id?: string
  label?: string
  type?: string
  disabled?: boolean
  value?: string
  onInputChange?: (value: string, name: string) => void
  onChange?: (value: any) => void
  variant?: 'filled' | 'outlined' | 'standard'
  labelDisabled?: boolean
  rootClassName?: string
}

export type CustomTextFieldProps = Props & TextFieldProps

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  type = 'text',
  disabled,
  value = '',
  onInputChange,
  className,
  rootClassName,
  children,
  onChange,
  variant = 'outlined',
  labelDisabled = false,
  ...textFieldProps
}) => {
  const onChangeData = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target
      const newValue: string = value
      onInputChange ? onInputChange(newValue, name) : onChange?.(event)
    },
    [onInputChange, onChange, type],
  )
  return (
    <TextField
      {...textFieldProps}
      label={label}
      type={type}
      disabled={disabled}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
        disabled: labelDisabled,
        shrink: variant === 'standard' ? true : undefined,
      }}
      className={classnames(classes.textField, className)}
      InputProps={{
        ...textFieldProps.InputProps,
        classes: {
          root: classnames(classes.root, textFieldProps.InputProps?.classes?.root, rootClassName),
          disabled: classes.disabled,
          underline: variant === 'standard' ? classes.underline : '',
          multiline: classes.multiline,
          // @ts-ignore
          notchedOutline: classnames(classes.notchedOutline, textFieldProps.InputProps?.classes.notchedOutline),
        },
      }}
      onChange={onChangeData}
      value={value || ''}
      variant={variant}
    >
      {children}
    </TextField>
  )
}

export default CustomTextField
