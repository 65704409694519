import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { store, persistor } from 'app/store'
import Routers from './Router'
import reportWebVitals from 'reportWebVitals'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import './index.css'
import { theme } from 'utils/theme'
import { PersistGate } from 'redux-persist/integration/react'
import Config from './constants/Config'
import VConsole from 'vconsole'

const container = document.getElementById('root')!
const root = createRoot(container)
if (Config.env === 'DEV') {
  new VConsole()
}

const router = createBrowserRouter(createRoutesFromElements(Routers))

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
