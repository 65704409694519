import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import CustomTextField from '../common/CustomTextField'
import { useSetState } from '../../hooks'
import { OutsideSaleITF } from '../../types/user'
import { saveSalesUser } from '../../apis'
import classes from './EditOutsideSales.module.less'
import { isValidEmail } from '../../utils/common'
import MobilePhone from '../common/MobilePhone'
import SnackBar from '../snackBar'

interface Props {
  onClose: () => void
  info?: OutsideSaleITF
  onSuccess?: (data: OutsideSaleITF, isEdit: boolean) => void
}

const EditOutsideSales: React.FC<Props> = ({ onClose, info, onSuccess }) => {
  const [data, setData] = useSetState<OutsideSaleITF>(info || ({} as OutsideSaleITF))
  const { Name, PhoneNumber, Email, id } = data || {}
  const [saving, setSaving] = useState(false)
  const disabled = useMemo(() => {
    return !isValidEmail(Email) || !Name || !PhoneNumber
  }, [Email, PhoneNumber, Email])
  const onInputChange = useCallback((value: string, name: string) => {
    // @ts-ignore
    setData({ [name]: value })
  }, [])
  const onChangePhone = useCallback((value: string) => {
    setData({ PhoneNumber: value })
  }, [])
  const onSaveBtn = useCallback(async () => {
    if (saving) return
    setSaving(true)
    const params = {
      Name,
      PhoneNumber,
      Email,
    }
    id && Object.assign(params, { SalesUserId: id })
    const result = await saveSalesUser<OutsideSaleITF>(params)
    SnackBar({ msg: 'Successfully saved', type: 'success' })
    result.id = result.pcId
    result.SalesUserId = result.pcId
    setSaving(false)
    onSuccess?.(result, !!id)
  }, [Name, PhoneNumber, Email, id, onSuccess, saving])
  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', padding: '24px' }}>
        <Typography
          sx={{
            flex: 1,
            color: '#333',
            fontWeight: '700',
            fontSize: '32px',
          }}
        >{`${id ? 'Edit' : 'Add'} Outside Sales`}</Typography>
        <IconButton onClick={onClose}>
          <Close htmlColor="#211F1F" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ color: '#8c8c8c', fontSize: '16px', margin: '16px 0 8px' }}>Name</Typography>
        <CustomTextField name="Name" label="" value={Name} onInputChange={onInputChange} fullWidth />
        <Typography sx={{ color: '#8c8c8c', fontSize: '16px', margin: '16px 0 8px' }}>Telephone</Typography>
        <MobilePhone onChangePhone={onChangePhone} value={PhoneNumber} />
        <Typography sx={{ color: '#8c8c8c', fontSize: '16px', margin: '16px 0 8px' }}>Email</Typography>
        <CustomTextField name="Email" label="" value={Email} onInputChange={onInputChange} fullWidth />
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <Button variant="outlined" onClick={onClose} sx={{ flex: 1, height: '50px', borderRadius: '12px' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSaveBtn}
          disabled={disabled}
          sx={{ flex: 1, height: '50px', borderRadius: '12px' }}
        >
          Save
          {saving && <CircularProgress sx={{ color: 'white', marginLeft: '12px' }} size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditOutsideSales
