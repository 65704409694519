import LogOutDialog from 'components/modal/LogOutDialog'
import React, { ReactNode, useCallback, useState } from 'react'
import { Box, Button, Typography, Switch } from '@mui/material'
import classes from './Settings.module.less'
import ContentWrap from 'components/setting/ContentWrap'
import CustomSelect, { CustomSelectChoice } from 'components/common/CustomSelect'
import CustomTextField from 'components/common/CustomTextField'
import { useAppSelector } from 'app/hooks'
import { actions, selectProfile } from 'app/profileSlice'
import classnames from 'classnames'
import { useSetState } from 'hooks'
import { updateUserProfile } from 'apis'
import { useAppDispatch } from 'app/hooks'
import SnackBar from 'components/snackBar'
import { UserITF } from 'types/user'
import useGetNoteTemplates from 'hooks/useGetNoteTemplate'
import MFADialog from 'components/modal/MFADialog'
import { LANGUAGE_ENUM } from '../constants/common'

interface ItemLabelProps {
  label: string
  className?: string
  children?: ReactNode
}

const ItemLabel: React.FC<ItemLabelProps> = ({ label, children, className }) => {
  return (
    <Box className={classnames(classes.item, className)}>
      <Typography className={classes.label}>{label}</Typography>
      <Box className={classes.itemRight}>{children}</Box>
    </Box>
  )
}
const Settings: React.FC = () => {
  const dispatch = useAppDispatch()
  const { template } = useGetNoteTemplates()
  const profile = useAppSelector(selectProfile)
  const [showLogout, setShowLogout] = useState<boolean>()
  const [data, setData] = useSetState({
    FullName: profile?.user?.UserPersonal.FullName?.en,
    NoteTemplate: profile?.user?.NoteTemplate,
    ClinicName: profile?.user?.ClinicName,
    Language: profile?.user?.Language || 'en',
    Change: false,
    MFAEnabled: !!profile?.user?.MFAEnabled,
    showMFADialog: false,
  })
  const MFARequired = !!profile?.user?.MFARequired
  console.log('profile', profile)
  const { ClinicName, NoteTemplate, FullName, Change, MFAEnabled, showMFADialog, Language } = data || {}
  const onClose = () => {
    setShowLogout(false)
  }
  const onChangeNoteTemplate = useCallback((value: CustomSelectChoice | null) => {
    value && setData({ NoteTemplate: value.value as string, Change: true })
  }, [])
  const onChangeLanguage = useCallback((value: CustomSelectChoice | null) => {
    value && setData({ Language: value.value as string, Change: true })
  }, [])
  const onInputChange = useCallback((value: string, name: string) => {
    // @ts-ignore
    setData({ [name]: value, Change: true })
  }, [])
  const onLogout = useCallback(() => {
    setShowLogout(true)
  }, [])
  const onSave = useCallback(() => {
    updateUserProfile<UserITF>({ FullName, ClinicName, NoteTemplate, Language }).then((res) => {
      SnackBar({ msg: 'Successfully saved', type: 'success' })
      dispatch(actions.loginSuccess(res))
    })
  }, [FullName, ClinicName, NoteTemplate, Language])
  return (
    <div className={classes.layout}>
      <Typography className={classes.setting}>Settings</Typography>
      <ContentWrap title="Account Info">
        <ItemLabel label="User Account">
          <CustomTextField value={profile?.user?.UserName} className={classes.textField} disabled />
          <Button variant="outlined" className={classes.logout} onClick={onLogout}>
            Logout
          </Button>
        </ItemLabel>
        <ItemLabel label="Full Name" className={classes.margin}>
          <CustomTextField
            name="FullName"
            value={FullName}
            className={classes.textFxield}
            onInputChange={onInputChange}
          />
        </ItemLabel>
        <ItemLabel label="Clinic Name" className={classes.margin}>
          <CustomTextField
            value={ClinicName}
            name="ClinicName"
            className={classes.textField}
            placeholder="Enter here"
            onInputChange={onInputChange}
          />
        </ItemLabel>
        <ItemLabel label="Languages" className={classes.margin}>
          <CustomSelect
            label=""
            sx={{ width: '100%' }}
            value={LANGUAGE_ENUM?.find((item1: CustomSelectChoice) => item1.value === Language)}
            onChange={onChangeLanguage}
            choices={LANGUAGE_ENUM || []}
          />
        </ItemLabel>
      </ContentWrap>
      <ContentWrap title="Note Settings" hiddenDivider={!MFARequired}>
        <ItemLabel label="Note Template">
          <CustomSelect
            label=""
            sx={{ width: '100%' }}
            value={template?.find((item1: CustomSelectChoice) => item1.value === NoteTemplate)}
            onChange={onChangeNoteTemplate}
            choices={template || []}
          />
        </ItemLabel>
      </ContentWrap>
      {MFARequired && (
        <ContentWrap title="Security Settings" hiddenDivider>
          <ItemLabel label="Turn on MFA verification">
            <span style={{ flex: 1 }} />
            <Switch
              checked={MFAEnabled}
              disabled={MFAEnabled}
              onChange={() => setData({ MFAEnabled: true, showMFADialog: true })}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </ItemLabel>
          {showMFADialog && <MFADialog onClose={() => setData({ showMFADialog: false })} />}
        </ContentWrap>
      )}
      <Button
        onClick={onSave}
        className={classes.save}
        variant="contained"
        disabled={!Change || !NoteTemplate || !FullName}
      >
        Save
      </Button>
      {showLogout && <LogOutDialog onClose={onClose} />}
    </div>
  )
}

export default Settings
