import React from 'react'
import { useParams } from 'react-router-dom'

const URLS = {
  Privacy: 'https://dev-env-pecker.s3.amazonaws.com/file/Subscriber.Agreement.html',
  Terms: 'https://prod-env-pecker.s3.amazonaws.com/file/Subscriber.Agreement.html',
  Support: '',
  Documentation: 'https://dev-env-pecker.s3.us-east-1.amazonaws.com/file/Instructions.html',
}
const WPeckerInfo = () => {
  const { type } = useParams()
  // @ts-ignore
  const url = URLS[type]
  if (type === 'Support') {
    return <div>Support Contact: hxie@altatech.dev</div>
  }
  if (!url) return null
  return (
    <iframe
      title={url}
      src="https://dev-env-pecker.s3.amazonaws.com/file/Subscriber.Agreement.html"
      style={{ height: '100vh' }}
      width="100%"
    />
  )
}

export default WPeckerInfo
