import React, { useEffect, useRef } from 'react'
import { Box, Dialog as DialogUi, Typography, Button, Drawer, IconButton } from '@mui/material'
import classes from './HIPAADialog.module.less'
import { useSetState } from 'hooks'
import { PATIENT_CONSENT } from 'pages/Transcript'
import Images from 'assets'
import Config from 'constants/Config'
import { Close } from '@mui/icons-material'

interface Props {
  onClose: (patientConsent: PATIENT_CONSENT) => void
}

const PatientTipDialog: React.FC<Props> = ({ onClose }) => {
  const [{ open, showText, showDisableTool }, setState] = useSetState({
    open: true,
    showText: false,
    showDisableTool: false,
  })
  const dataRef = useRef({ patientConsent: PATIENT_CONSENT.AutoAgreed, timer: 0 })

  const removeDialog = (patientConsent: PATIENT_CONSENT) => {
    dataRef.current.patientConsent = patientConsent
    setState({ open: false, showDisableTool: false })
    onClose?.(patientConsent)
    if (dataRef.current.timer) {
      clearTimeout(dataRef.current.timer)
    }
  }

  const onDisable = () => {
    setState({ showDisableTool: true })
    const timer = setTimeout(() => {
      removeDialog(PATIENT_CONSENT.Disagreed)
      clearTimeout(timer)
    }, 5000)
    //@ts-ignore
    dataRef.current.timer = timer
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dataRef.current.patientConsent === PATIENT_CONSENT.AutoAgreed) {
        removeDialog(PATIENT_CONSENT.AutoAgreed)
      }
      clearTimeout(timer)
    }, 60000)
  }, [])

  if (showDisableTool) {
    return (
      <DialogUi open={showDisableTool} classes={{ paper: classes.container }}>
        <IconButton onClick={() => removeDialog(PATIENT_CONSENT.Disagreed)} className={classes.close}>
          <Close htmlColor="#211F1F" />
        </IconButton>
        <img
          src={Images.disableTool}
          alt=""
          style={{ width: 120, height: 120, display: 'block', margin: '24px auto' }}
        />
        <Typography sx={{ fontSize: '32px', color: '#333', fontWeight: 'bold', textAlign: 'center' }}>
          Tool Disabled
        </Typography>
        <Typography sx={{ fontSize: '20px', color: '#8c8c8c', textAlign: 'center' }}>
          Woodpecker Copilot has been disabled for this session. You can continue with your patient consultation.
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#8c8c8c', textAlign: 'center', marginTop: '8px' }}>
          This window will close automatically in 5 seconds
        </Typography>
      </DialogUi>
    )
  }

  return (
    <DialogUi open={open} classes={{ paper: classes.container }}>
      <audio autoPlay style={{ display: 'none' }} preload="auto">
        <source src={Config.dingAudio} type="audio/mpeg" />
      </audio>
      <Typography sx={{ fontSize: '24px', color: '#333', fontWeight: 'bold' }}>
        Reminder: Obtain Patient Consent
      </Typography>
      <Typography sx={{ fontSize: '18px', color: '#333', marginTop: '16px' }}>
        Before starting this session, please let the patient know that their conversation will be transcribed. If the
        patient consents, you may proceed; if not, please disable the tool for this session. If you’ve already received
        consent from this patient previously, no need to ask again.
        <br />
        For sample consent language,{' '}
        <span
          style={{ color: '#3333F5', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setState({ showText: true })}
        >
          click here
        </span>
        . Feel free to adjust the wording as needed.
        <br />
        Click "Continue" to confirm that you have obtained verbal consent and proceed with transcription.
      </Typography>
      <Typography
        sx={{
          borderRadius: '12px',
          color: '#8C8C8C',
          marginTop: '16px',
          padding: '16px',
          background: '#F7F8FA',
          fontStyle: 'italic',
          fontSize: '16px',
          display: showText ? 'block' : 'none',
        }}
      >
        I'm using a tool called Woodpecker Copilot to help document our visit, so I can focus more on your care and less
        on the computer. This tool listens to our conversation and creates a summary, which I’ll review for accuracy.
        The audio is encrypted, HIPAA compliant, and anonymous data may be used to improve the tool’s accuracy, but your
        identity and medical information will remain private.
      </Typography>
      <Box sx={{ display: 'flex', margin: '48px auto 24px', width: '100%' }}>
        <Button variant="outlined" onClick={onDisable} color="primary" sx={{ flex: 1, borderRadius: '12px' }}>
          Disable Tool
        </Button>
        <span style={{ width: '20px' }}></span>
        <Button
          variant="contained"
          onClick={() => removeDialog(PATIENT_CONSENT.Agreed)}
          color="primary"
          sx={{ flex: 1, borderRadius: '12px' }}
        >
          Continue
        </Button>
      </Box>
    </DialogUi>
  )
}

export const H5PatientTipDialog: React.FC<Props> = ({ onClose }) => {
  const [{ open, showText, showDisableTool }, setState] = useSetState({
    open: true,
    showText: false,
    showDisableTool: false,
  })
  const dataRef = useRef({ patientConsent: PATIENT_CONSENT.AutoAgreed, timer: 0 })

  const removeDialog = (patientConsent: PATIENT_CONSENT) => {
    dataRef.current.patientConsent = patientConsent
    setState({ open: false, showDisableTool: false })
    onClose?.(patientConsent)
    if (dataRef.current.timer) {
      clearTimeout(dataRef.current.timer)
    }
  }

  const onDisable = () => {
    setState({ showDisableTool: true })
    const timer = setTimeout(() => {
      removeDialog(PATIENT_CONSENT.Disagreed)
      clearTimeout(timer)
    }, 5000)
    //@ts-ignore
    dataRef.current.timer = timer
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dataRef.current.patientConsent === PATIENT_CONSENT.AutoAgreed) {
        removeDialog(PATIENT_CONSENT.AutoAgreed)
      }
      clearTimeout(timer)
    }, 60000)
  }, [])

  if (showDisableTool) {
    return (
      <DialogUi open={showDisableTool} classes={{ paper: classes.container }}>
        <IconButton onClick={() => removeDialog(PATIENT_CONSENT.Disagreed)} className={classes.close}>
          <Close htmlColor="#211F1F" />
        </IconButton>
        <img src={Images.disableTool} alt="" style={{ width: 80, height: 80, display: 'block', margin: '16px auto' }} />
        <Typography sx={{ fontSize: '24px', color: '#333', fontWeight: 'bold', textAlign: 'center' }}>
          Tool Disabled
        </Typography>
        <Typography sx={{ fontSize: '16px', color: '#8c8c8c', textAlign: 'center' }}>
          Woodpecker Copilot has been disabled for this session. You can continue with your patient consultation.
        </Typography>
        <Typography sx={{ fontSize: '12px', color: '#8c8c8c', textAlign: 'center', marginTop: '8px' }}>
          This window will close automatically in 5 seconds
        </Typography>
      </DialogUi>
    )
  }

  return (
    <Drawer anchor="bottom" open={open} classes={{ paper: classes.h5UpdatePaper }}>
      <Typography sx={{ fontSize: '20px', color: '#333', fontWeight: 'bold' }}>
        Reminder: Obtain Patient Consent
      </Typography>
      <Typography sx={{ fontSize: '16px', color: '#8C8C8C', marginTop: '16px' }}>
        Before starting this session, please let the patient know that their conversation will be transcribed. If the
        patient consents, you may proceed; if not, please disable the tool for this session. If you’ve already received
        consent from this patient previously, no need to ask again.
        <br />
        For sample consent language,{' '}
        <span
          style={{ color: '#3333F5', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setState({ showText: true })}
        >
          click here
        </span>
        . Feel free to adjust the wording as needed.
        <br />
        Click "Continue" to confirm that you have obtained verbal consent and proceed with transcription.
      </Typography>
      <Typography
        sx={{
          borderRadius: '12px',
          color: '#8C8C8C',
          marginTop: '16px',
          padding: '16px',
          background: '#F7F8FA',
          fontStyle: 'italic',
          fontSize: '12px',
          display: showText ? 'block' : 'none',
        }}
      >
        I'm using a tool called Woodpecker Copilot to help document our visit, so I can focus more on your care and less
        on the computer. This tool listens to our conversation and creates a summary, which I’ll review for accuracy.
        The audio is encrypted, HIPAA compliant, and anonymous data may be used to improve the tool’s accuracy, but your
        identity and medical information will remain private.
      </Typography>
      <Box sx={{ display: 'flex', margin: '16px auto 0', width: '100%' }}>
        <Button variant="outlined" onClick={onDisable} color="primary" sx={{ flex: 1, borderRadius: '12px' }}>
          Disable Tool
        </Button>
        <span style={{ width: '16px' }}></span>
        <Button
          variant="contained"
          onClick={() => removeDialog(PATIENT_CONSENT.Agreed)}
          color="primary"
          sx={{ flex: 1, borderRadius: '12px' }}
        >
          Continue
        </Button>
      </Box>
    </Drawer>
  )
}

export default PatientTipDialog
