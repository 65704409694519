import React, { useState, useEffect } from 'react'
import { Button, Dialog as DialogUi, Typography, Box } from '@mui/material'
import classes from './ConsultationsDialog.module.less'
import { getConsultationDetail } from 'apis'
import { IConsultation } from 'types/transcription'
import TranscriptionItem from 'components/transcript/TranscriptionItem'

interface Props {
  onClose: () => void
  ConsultationId: string
}
const ConsultationsDialog: React.FC<Props> = ({ onClose, ConsultationId }) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<IConsultation>({} as IConsultation)
  const { Transcript, Title = '' } = data
  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }

  useEffect(() => {
    if (!ConsultationId) return
    getConsultationDetail<IConsultation>({ cId: ConsultationId }).then((res) => {
      setData(res)
      setOpen(true)
    })
  }, [ConsultationId])

  return (
    <DialogUi open={open} classes={{ paper: classes.container }}>
      <Typography variant="h5" className={classes.title}>
        {Title}
      </Typography>
      <Box className={classes.body}>
        {Transcript?.map((item) => {
          return <TranscriptionItem item={item} key={item.Time} />
        })}
      </Box>
      <Button variant="contained" onClick={removeDialog} color="primary" className={classes.btn}>
        Close
      </Button>
    </DialogUi>
  )
}

export default ConsultationsDialog
