import { useAppSelector } from 'app/hooks'
import { selectProfile } from 'app/profileSlice'

const usePermissions = () => {
  const profile = useAppSelector(selectProfile)
  const user = profile.user
  return user?.UserContext?.PermissionsHash || {}
}

export default usePermissions
