import React, { useEffect } from 'react'
import { Dialog, Typography, DialogTitle, DialogContent, IconButton, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { getExportDetail } from 'apis'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import useSetState from 'hooks/useSetState'
import { format } from 'date-fns'
import classes from 'pages/AdminConsultations.module.less'

function formatTime(seconds: number = 0) {
  if (seconds >= 3600) {
    return (seconds / 3600).toFixed(2) + ' Hours'
  } else {
    return (seconds / 60).toFixed(2) + ' min'
  }
}

const columns: GridColDef[] = [
  {
    field: 'FriendlyId',
    headerName: 'ID',
    sortable: false,
    disableColumnMenu: true,
    width: 65,
  },
  {
    field: 'NoteTemplate',
    headerName: 'Category',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 280,
  },
  {
    field: 'Disease',
    headerName: 'Diseases/Conditions',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Disease?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
  {
    field: 'Medicine',
    headerName: 'Medications/Procedures',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Medicine?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
  {
    field: 'Language',
    headerName: 'Language',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 100,
  },
  {
    field: 'Duration',
    headerName: 'Duration',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 120,
    valueGetter: ({ row }) => formatTime(row.Duration),
  },
  // {
  //   field: 'AudioUrl',
  //   headerName: 'Audio File',
  //   sortable: false,
  //   disableColumnMenu: true,
  //   minWidth: 200,
  //   renderCell: ({ row }) => {
  //     const segments = row?.AudioUrl?.split('/')
  //     return <span style={{ color: '#5a5af6', textDecoration: 'underline' }}>{segments[segments.length - 1]}</span>
  //   },
  // },
  // {
  //   field: 'Status',
  //   headerName: 'Al Transcript (JSON)',
  //   sortable: false,
  //   disableColumnMenu: true,
  //   minWidth: 300,
  // },
]
const PAGE_SIZE = 100

interface Props {
  onClose: () => void
  eId: string
}
const ExportDetail: React.FC<Props> = ({ onClose, eId }) => {
  const [state, setState] = useSetState({
    open: false,
    page: 0,
    pageSize: PAGE_SIZE,
    Consultations: [],
    CustomerName: '',
    ExportDate: null as unknown as Date,
    FullName: '',
    Note: '',
    Status: '',
  })
  const { open, Consultations, page, pageSize, CustomerName, ExportDate, FullName, Note, Status } = state
  const removeDialog = () => {
    setState({ open: false })
    onClose?.()
  }

  useEffect(() => {
    if (!eId) return
    getExportDetail({ eId }).then((res: any) => {
      setState({ ...res, open: true })
    })
  }, [eId])

  return (
    <Dialog open={open} maxWidth="xl" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', padding: '24px' }}>
        <Typography sx={{ flex: 1, color: '#333', fontWeight: '700', fontSize: '32px' }}>Export</Typography>
        <IconButton onClick={removeDialog}>
          <Close htmlColor="#211F1F" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.detailTitle}>
          {[
            { label: 'Customer Name', value: CustomerName },
            { label: 'User', value: FullName },
            { label: 'Export Date', value: format(new Date(ExportDate || null), 'MM/dd/yyyy HH:mm a') },
            { label: 'Status', value: Status },
            { label: 'Note', value: Note },
          ].map((v, i) => {
            return (
              <Typography key={i}>
                <span style={{ fontWeight: 700 }}>• {v.label}: </span>
                <span>{v.value}</span>
              </Typography>
            )
          })}
        </Box>
        <Box sx={{ overflowX: 'auto' }}>
          <Box sx={{ minWidth: '1400px' }}>
            <DataGrid
              rows={Consultations}
              rowCount={Consultations.length}
              columns={columns}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              pageSizeOptions={[PAGE_SIZE]}
              paginationModel={{
                pageSize,
                page,
              }}
              paginationMode="server"
              onPaginationModelChange={(pagination) => {
                setState({
                  page: pagination.pageSize === pageSize ? pagination.page : 0,
                  pageSize: pagination.pageSize,
                })
              }}
              localeText={{ noRowsLabel: 'No Data' }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ExportDetail
