import React from 'react'

export type CustomQueue<T> = {
  add: (element: T) => void
  remove: () => T | undefined
  clear: () => void
  first: T | undefined
  last: T | undefined
  size: number
  queue: T[]
}

export function useQueue<T>(initialValue: T[] = []): CustomQueue<T> {
  const [queue, setQueue] = React.useState(initialValue)

  const add = React.useCallback((element: T) => {
    setQueue((q) => [...q, element])
  }, [])

  const remove = React.useCallback(() => {
    let removedElement

    setQueue(([first, ...q]) => {
      removedElement = first
      return q
    })

    return removedElement
  }, [])

  const clear = React.useCallback(() => {
    setQueue([])
  }, [])

  return {
    add,
    remove,
    clear,
    first: queue[0],
    last: queue[queue.length - 1],
    size: queue.length,
    queue,
  }
}
