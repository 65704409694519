import { useEffect } from 'react'
import { Box, Pagination, Typography } from '@mui/material'
import { useSetState } from 'hooks'
import classes from './HistoryList.module.less'
import Images from 'assets'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import PeriodSelect from 'components/common/PeriodSelect'
import { formatTime, PERIOD_OPTIONS } from 'utils/common'
import { getConsultationOverview, getUserConsultationHistory } from 'apis'
import { ArrowOutward, SouthEast } from '@mui/icons-material'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
const PAGE_SIZE = 50

const Summary = {
  ConsultationCount: {
    label: 'Consultations',
    icon: Images.consultations,
    number: 0,
    percent: 0,
  },
  DurationSum: {
    label: 'Total Hours',
    icon: Images.hours,
    number: 0,
    percent: 0,
  },
}

const columns: GridColDef[] = [
  {
    field: 'CreatedDate',
    headerName: 'Date',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 220,
    valueGetter: ({ row }) => format(new Date(row.CreatedDate || null), 'MM/dd/yyyy HH:mm a'),
  },
  {
    field: 'Title',
    headerName: 'Title',
    minWidth: 300,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Diseases',
    headerName: 'Diseases/Conditions',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 300,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Keywords?.Disease?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
  {
    field: 'Medicine',
    headerName: 'Medications/Procedures',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 300,
    cellClassName: classes.cellDiseases,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.Keywords?.Medicine?.map((v: string) => (
            <span key={v} className={classes.chip}>
              {v}
            </span>
          ))}
        </>
      )
    },
  },
  {
    field: 'Duration',
    headerName: 'Duration',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 120,
    valueGetter: ({ row }) => formatTime(row.Duration),
  },
  {
    field: 'NoteTemplate',
    headerName: 'Note Template',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 280,
  },
]

const HistoryList = () => {
  const navigate = useNavigate()

  const [state, setState] = useSetState({
    page: 0,
    rowCount: 0,
    period: PERIOD_OPTIONS.find((item) => item.value === '7-Day'),
    render: false,
    rows: [] as Record<string, any>[],
    loading: true,
  })
  const { page, period, rows, loading, rowCount } = state
  const tu = period?.value

  useEffect(() => {
    getConsultationOverview({ tu }).then((res: any) => {
      Summary.ConsultationCount.number = res.ConsultationCount
      Summary.ConsultationCount.percent = res.ConsultationGrowthRate
      Summary.DurationSum.number = res.DurationSum
      Summary.DurationSum.percent = res.DurationGrowthRate
      setState((pre) => ({ ...pre, render: !pre.render }))
    })
  }, [tu])

  useEffect(() => {
    setState({ loading: true })
    getUserConsultationHistory({ tu, skip: page * PAGE_SIZE, take: PAGE_SIZE })
      .then((res: any) => {
        const rows = res?.Consultations || []
        setState((pre) => ({ ...pre, rows, rowCount: res?.Total }))
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [tu, page, setState])

  return (
    <Box className={classes.layout}>
      <Box className={classes.header}>
        <span className={classes.headerText}>Consultations</span>
        <PeriodSelect period={period} onChange={(value) => setState({ period: value, page: 0 })} label="" />
      </Box>

      <Box className={classes.body}>
        <Box className={classes.table}>
          <Typography className={classes.tableTitle}>Overview</Typography>
          <Box className={classes.summary}>
            {Object.entries(Summary).map(([key, item], index) => {
              const color = item.percent >= 0 ? '#2AA33A' : '#D3543A'
              return (
                <Box key={index} className={classes.summaryItem}>
                  <Box>
                    <Typography className={classes.leftText}>{item.label}</Typography>
                    <Typography className={classes.number}>{item.number.toLocaleString('en-US')}</Typography>
                    <Typography className={classes.percent} sx={{ color }}>
                      {item.percent >= 0 ? '+' : '-'}
                      <span style={{ margin: '0 4px' }}>{Math.abs(item.percent)}%</span>
                      {item.percent >= 0 ? <ArrowOutward /> : <SouthEast />}
                    </Typography>
                  </Box>
                  <img src={item.icon} alt="logo" />
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box className={classes.table}>
          <Typography className={classes.tableTitle}>Table of Consultations</Typography>
          <Box style={{ overflowX: 'auto' }}>
            <DataGrid
              rows={rows}
              rowCount={rowCount}
              columns={columns}
              rowSelection={false}
              getRowHeight={() => 'auto'}
              localeText={{ noRowsLabel: 'No Data' }}
              classes={{
                virtualScroller: classes.virtualScroller,
                row: classes.row,
                main: classes.main,
                cell: classes.cell,
                columnHeader: classes.columnHeader,
                columnSeparator: classes.columnSeparator,
              }}
              onRowClick={({ row }) => {
                navigate(`/history/${row.ConsultationId}`, {
                  replace: false,
                  state: { showBack: true },
                })
              }}
              slots={{
                pagination: () => {
                  return (
                    <Pagination
                      className={classes.pagination}
                      color="primary"
                      count={Math.ceil(rowCount / PAGE_SIZE)}
                      page={page + 1}
                      showFirstButton
                      showLastButton
                      onChange={(event, page) => {
                        setState({ page: page - 1 })
                      }}
                    />
                  )
                },
              }}
              loading={loading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default HistoryList
