import React from 'react'
import { Box, Typography } from '@mui/material'
import { ISmartNote } from 'types/transcription'
import classes from './Note.module.less'
interface Props {
  smartNote?: ISmartNote
}

const Note: React.FC<Props> = ({ smartNote }) => {
  const { Name, Value } = smartNote || {}
  const data = Value
  const arr = Array.isArray(data) ? data : [data]
  const isNa = !data || arr?.length === 0
  return (
    <Box className={classes.container}>
      <Typography className={classes.label}>{Name}</Typography>
      {isNa ? (
        <Typography className={classes.na}>N/A</Typography>
      ) : (
        arr?.map((item, index) => {
          return (
            <Box key={`${item}${index}`} className={classes.item}>
              <Box className={classes.dot} />
              <Typography className={classes.text}>{item}</Typography>
            </Box>
          )
        })
      )}
    </Box>
  )
}

export default Note
