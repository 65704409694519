import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import { ExpandMore } from '@mui/icons-material'
export interface CustomSelectChoice {
  label: string
  value: string | number
  [key: string]: any
}
interface CustomSelectProps {
  choices?: Array<CustomSelectChoice>
  label?: string
  value?: CustomSelectChoice | null | undefined
  width?: string | number
  height?: string | number
  onChange?: (item: CustomSelectChoice | null) => void
  error?: boolean
  loading?: boolean
  sx?: SxProps<Theme>
  sxSelect?: SxProps<Theme>
}
const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  value,
  onChange,
  choices,
  error,
  sx,
  loading,
  sxSelect = {},
}) => {
  const [rawValue, setRawValue] = useState<string | undefined>(value?.value as string)
  const key = useMemo(() => `select-${loading}-${Math.random()}`, [loading])
  const handleChange = (event: SelectChangeEvent) => {
    setRawValue(event.target.value)
    onChange?.(choices?.find((item) => item.value === event.target.value) || null)
  }
  useEffect(() => {
    // @ts-ignore
    setRawValue(value?.value || null)
  }, [value])
  return (
    <FormControl sx={sx} key={key}>
      <InputLabel error={error} sx={{ lineHeight: 1.2 }}>
        {label}
      </InputLabel>
      <Select
        sx={{ height: 52, borderRadius: '12px', backgroundColor: '#F5F5F5', ...sxSelect }}
        value={rawValue || ''}
        onChange={handleChange}
        label={label}
        error={error}
        IconComponent={ExpandMore}
      >
        {choices?.map((item, index) => {
          return (
            <MenuItem value={item.value} key={`${item.value}-${index}`}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default CustomSelect
