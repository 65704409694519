import React from 'react'
import classes from './PdfOutsideSales.module.less'
import { Box, Typography } from '@mui/material'
import Images from '../assets'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'

import { Link, useParams } from 'react-router-dom'
import Config from '../constants/Config'

const PdfOutsideSales: React.FC = () => {
  const { id } = useParams<Record<string, string>>()
  const currentDomain = window.location.origin

  return (
    <Box className={classes.container}>
      <img src={Images.pdfHeader} alt="logo" className={classes.pdfHeader} />
      <Box className={classes.content}>
        <Box className={classes.top}>
          <Typography className={classes.title}>
            <img src={Images.logo} alt="logo" className={classes.logo} />
            Woodpecker Copilot
          </Typography>
          <Link className={classes.link} to={`${currentDomain}/?su=${id}`}>
            {'https://copilot.wpecker.ai >>'}
          </Link>
        </Box>
        <Typography className={classes.titleScribe}>
          Your Free, Trusted <span style={{ color: '#46CABE' }}>{'AI\n'}</span> Medical Scribe Assistant
        </Typography>
        <Typography className={classes.titleScribeDes}>
          Developed by Silicon Valley physicians and engineers, Woodpecker Copilot reduces clinician burnout and
          enhances patient care by relieving doctors of the documentation burden.
        </Typography>
        <Box className={classes.chooseContainer}>
          <Typography className={classes.choose}>Why Choose US ?</Typography>
          <ArrowOutwardIcon style={{ transform: 'rotate(90deg)' }} />
        </Box>
        <Typography className={classes.whyDes}>
          <span style={{ fontWeight: 600 }}>HIPAA Compliant & Encrypted: </span>Highest data security standard; PHI is{' '}
          <span style={{ fontWeight: 600 }}>NOT</span> stored.
        </Typography>
        <Typography className={classes.whyDes}>
          <span style={{ fontWeight: 600 }}>Real-Time Documentation: </span>Accurate medical notes in{' '}
          <span style={{ fontWeight: 600 }}>30 seconds</span>
        </Typography>
        <Typography className={classes.whyDes}>
          <span style={{ fontWeight: 600 }}>Saves 2 Hours Daily </span>
        </Typography>
        <Typography className={classes.whyDes}>
          <span style={{ fontWeight: 600 }}>Over 20 Specialty-specific </span>medical note templates
        </Typography>
        <Typography className={classes.whyDes}>
          <span style={{ fontWeight: 600 }}>Multilingual Support: </span>English, Spanish, Mandarin, and Vietnamese.
        </Typography>
      </Box>
      <Box className={classes.bottom}>
        <Box className={classes.bottomLeft}>
          <Typography className={classes.change}>Be Part of the</Typography>
          <Box className={classes.changeWrap}>
            <Typography className={classes.change}>Change</Typography>
            <img src={Images.hand} className={classes.hand} />
          </Box>
          <Typography className={classes.qrText}>
            Scan the QR code or{' '}
            <Link to={`${currentDomain}/?su=${id}`} style={{ color: 'white' }}>
              click here
            </Link>{' '}
            to try it today{' '}
          </Typography>
        </Box>
        <img src={`${Config.s3Url}/qrcode/${id}.png`} alt="logo" className={classes.qr} />
      </Box>
      <Typography className={classes.contact}>
        {'For support or questions, please contact:\n' + ' woodpeckercopilot@gmail.com'}
      </Typography>
    </Box>
  )
}
export default PdfOutsideSales
