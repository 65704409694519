import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Pagination, PaginationItem } from '@mui/material'
import { useSetState } from 'hooks'
import classes from './OutsideSales.module.less'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { formatPhoneNumber } from 'utils/common'
import { getSalesUserList } from 'apis'
import { SkipPrevious, SkipNext, Add } from '@mui/icons-material'
import EditOutsideSales from '../components/modal/EditOutsideSales'
import { OutsideSaleITF } from '../types/user'
import Images from '../assets'
import { useNavigate } from 'react-router-dom'

const PAGE_SIZE = 50

const LinkUrl: React.FC<{ url: string }> = ({ url }) => {
  return (
    <Typography
      onClick={(e) => {
        e.stopPropagation()
        url && window.open(url, '_blank')
      }}
      className={classes.link}
    >
      {url}
    </Typography>
  )
}
const OutsideSales = () => {
  const navigate = useNavigate()

  const [state, setState] = useSetState<{
    page: number
    total: number
    jump: string
    render: boolean
    loading: boolean
    showModal: boolean
    info?: OutsideSaleITF
    rows: OutsideSaleITF[]
  }>({
    page: 0,
    render: false,
    rows: [],
    loading: true,
    showModal: false,
    total: 0,
    jump: '',
  })
  const { rows, loading, showModal, total, info, page, render, jump } = state
  const jumpRef = useRef<number>()
  const { columns } = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'FriendlyId',
        headerName: 'Friendly ID',
        sortable: false,
        disableColumnMenu: true,
        width: 100,
      },
      {
        field: 'Name',
        headerName: 'Name',
        sortable: false,
        disableColumnMenu: true,
        width: 165,
      },
      {
        field: 'Telephone',
        headerName: 'Telephone',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => formatPhoneNumber(row.PhoneNumber),
        width: 142,
      },
      {
        field: 'Email',
        headerName: 'Email',
        sortable: false,
        disableColumnMenu: true,
        minWidth: 240,
      },
      {
        field: 'InvitedNumber',
        headerName: 'Number Of Invited',
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const number = row.InvitedNumber || 0
          if (number === 0) return number
          return (
            <Typography
              className={classes.link}
              onClick={() => {
                navigate('/adminConsultations', {
                  state: { invitorId: row.id },
                })
              }}
            >
              {number}
            </Typography>
          )
        },
        minWidth: 165,
      },
      {
        field: 'Source',
        headerName: 'QR code',
        sortable: false,
        disableColumnMenu: true,
        minWidth: 215,
        renderCell: ({ row }) => <LinkUrl url={row.QrCodeFile} />,
      },
      {
        field: 'Status',
        headerName: 'PDF link',
        sortable: false,
        disableColumnMenu: true,
        minWidth: 215,
        renderCell: ({ row }) => <LinkUrl url={row.PDFFile} />,
      },
      {
        field: 'edit',
        headerName: 'Edit',
        sortable: false,
        disableColumnMenu: true,
        minWidth: 160,
        renderCell: ({ row }) => {
          return (
            <Button
              variant="outlined"
              onClick={() => {
                setState({ showModal: true, info: row })
              }}
              className={classes.editBtn}
            >
              <img src={Images.editTheme} className={classes.editIcon} />
              Edit
            </Button>
          )
        },
      },
    ]
    return {
      columns,
    }
  }, [])

  const onJump = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
    if (jumpRef.current) {
      clearTimeout(jumpRef.current as number)
    }
    jumpRef.current = window.setTimeout(() => {
      // @ts-ignore
      setState({ page: Math.max(Number(value) - 1, 0) })
    }, 3000)
  }
  const fetchData = useCallback(() => {
    setState({ loading: true })
    getSalesUserList<{ SalesUsers: OutsideSaleITF[]; Total: number }>({
      skip: page * PAGE_SIZE,
      take: PAGE_SIZE,
    })
      .then((res: any) => {
        const rows = res?.SalesUsers || []
        setState({ page, rows, total: res?.Total || 0 })
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [page])

  const onSuccess = useCallback(
    (data: OutsideSaleITF, isEdit: boolean) => {
      if (isEdit) {
        const index = rows.findIndex((item) => item.id === data.id)
        const nextData = [...rows]
        if (index > -1) {
          nextData.splice(index, 1, data)
          setState({ rows: [...nextData], showModal: false })
        }
      } else {
        if (page === 0) {
          fetchData()
        }
        setState({ page: 0, showModal: false })
      }
    },
    [fetchData, page, rows],
  )
  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Box className={classes.layout}>
      <Box className={classes.header}>
        <Typography className={classes.headerText} style={{ flex: 1 }}>
          Outside sales
        </Typography>
      </Box>

      <Box className={classes.body}>
        <Box className={classes.table}>
          <Box className={classes.filter}>
            <Typography className={classes.tableTitle}>Table Of Outside Sales</Typography>
            <Button
              variant="contained"
              className={classes.add}
              onClick={() => setState({ showModal: true, info: undefined })}
            >
              <Add />
              Add Outside Sales
            </Button>
          </Box>
          <DataGrid
            classes={{
              main: classes.dataGrid,
              row: classes.row,
              columnHeader: classes.columnHeader,
            }}
            rows={rows}
            rowCount={total}
            columns={columns}
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            pagination
            loading={loading}
            localeText={{ noRowsLabel: 'No Data' }}
            onRowSelectionModelChange={(selection) => {
              setState({ render: !render })
            }}
            components={{
              Pagination: () => {
                return (
                  <>
                    <Pagination
                      className={classes.pagination}
                      color="primary"
                      count={Math.ceil(total / PAGE_SIZE)}
                      page={page + 1}
                      showFirstButton
                      showLastButton
                      onChange={(event, page) => {
                        setState({ page: page - 1 })
                      }}
                      style={{ justifyContent: 'flex-end' }}
                      renderItem={(item) => {
                        return <PaginationItem slots={{ first: SkipPrevious, last: SkipNext }} {...item} />
                      }}
                    />
                    <Box sx={{ flex: 1 }}>
                      Jump To
                      <input
                        type="number"
                        value={jump}
                        onChange={(e) => onJump(e.target.value, 'jump')}
                        style={{ width: '60px', margin: '0 8px', textAlign: 'center' }}
                      />
                      Page
                    </Box>
                  </>
                )
              },
            }}
          />
        </Box>
      </Box>
      {showModal && (
        <EditOutsideSales
          onSuccess={onSuccess}
          info={info}
          onClose={() => {
            setState({ showModal: false })
          }}
        />
      )}
    </Box>
  )
}
export default OutsideSales
