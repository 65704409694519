import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { IConsultation } from '../types/transcription'

export interface TranscriptSlice {
  consultation?: IConsultation
}

const INITIAL_STATE: TranscriptSlice = {}

interface Payload extends Partial<IConsultation> {
  pcId: string
}

const transcriptSlice = createSlice({
  name: 'transcript',
  initialState: INITIAL_STATE,
  reducers: {
    updateTitle: (state, { payload }: PayloadAction<Payload>) => {
      state.consultation = payload as unknown as IConsultation
    },
  },
})
export const selectTranscription = (state: RootState) => state.transcript.consultation

export const { updateTitle } = transcriptSlice.actions

export default transcriptSlice.reducer
