import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import classes from 'components/common/SwitchTab.module.less'
import classnames from 'classnames'

export interface ITab {
  label: string
}

interface Props {
  tabs: Array<ITab>
  value?: number
  onChange?: (value: number) => void
}

const SwitchTab: React.FC<Props> = ({ tabs = [], value, onChange }) => {
  const [index, setIndex] = useState(0 || value)
  useEffect(() => {
    if (typeof value !== 'undefined' && value !== index) {
      setIndex(value)
    }
  }, [index, value])
  return (
    <Box className={classes.tab}>
      {tabs?.map((item, i) => {
        const isActive = index === i
        return (
          <Button
            className={classnames(classes.tabItem, { [classes.tabItemActive]: isActive })}
            variant="text"
            onClick={() => {
              setIndex(i)
              onChange?.(i)
            }}
            key={item.label}
          >
            {item.label}
          </Button>
        )
      })}
    </Box>
  )
}
export default SwitchTab
