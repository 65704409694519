import { UserITF } from 'types/user'
import { IDeepgramKey } from '../types/transcription'

export const USER_TOKEN = 'pecker_userToken'
export const USER = 'pecker_user'
export const DEEPGRAM = 'deepgram'
let cacheData: { [key: string]: any } = {}

const ALL_SAVE = [USER_TOKEN]
const clearData = () => {
  cacheData = {}
  ALL_SAVE.forEach((key) => localStorage.removeItem(key))
}

function getAuthToken() {
  if (cacheData[USER_TOKEN]) return cacheData[USER_TOKEN]
  return localStorage.getItem(USER_TOKEN)
}
function setAuthToken(token: string | undefined) {
  cacheData[USER_TOKEN] = token
  localStorage.setItem(USER_TOKEN, token || '')
}
function getDeepgramKey() {
  if (cacheData[DEEPGRAM]) return cacheData[DEEPGRAM]
  // @ts-ignore
  return localStorage.getItem(DEEPGRAM) ? (JSON.parse(localStorage.getItem(DEEPGRAM)) as IDeepgramKey) : undefined
}

function setDeepgramKey(data: IDeepgramKey) {
  cacheData[DEEPGRAM] = data
  localStorage.setItem(DEEPGRAM, JSON.stringify(data))
}
function getUser() {
  if (cacheData[USER]) return cacheData[USER]
  // @ts-ignore
  return localStorage.getItem(USER) ? (JSON.parse(localStorage.getItem(USER)) as UserITF) : undefined
}

function setUser(user: UserITF) {
  cacheData[USER] = user
  Storages.setAuthToken(user.UserToken)
  localStorage.setItem(USER, JSON.stringify(user))
}
export const removeAllDate = () => {
  clearData()
}

const Storages = {
  getAuthToken,
  setAuthToken,
  removeAllDate,
  getUser,
  setUser,
  setDeepgramKey,
  getDeepgramKey,
}
export default Storages
