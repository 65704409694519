import { visitorLogin } from 'apis'
import { useCallback, useEffect, useState } from 'react'
import Storages from '../utils/Storages'
import { UserITF } from '../types/user'
import { actions } from '../app/profileSlice'
import { useAppDispatch } from '../app/hooks'

const useVisitorLogin = () => {
  const dispatch = useAppDispatch()
  const [state, setState] = useState<{ loading: boolean; isLogin: boolean; user?: UserITF }>({
    loading: true,
    isLogin: false,
  })
  const { isLogin, loading, user } = state
  const login = useCallback(() => {
    visitorLogin<UserITF>({}).then((res) => {
      dispatch(actions.loginSuccess(res))
      setState({ loading: false, isLogin: true, user: res })
    })
  }, [])
  useEffect(() => {
    if (Storages.getAuthToken()) {
      setState({ loading: false, isLogin: true, user: Storages.getUser() })
    } else {
      login()
    }
  }, [login])
  return {
    loading,
    isLogin,
    user,
  }
}

export default useVisitorLogin
