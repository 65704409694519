import React from 'react'
import { Box, Button, Dialog as DialogUi, IconButton, Typography, Tooltip } from '@mui/material'
import classes from './MFADialog.module.less'
import { Close } from '@mui/icons-material'
import CustomTextField from 'components/common/CustomTextField'
import { generateOtpSecret, enableMFAVerify } from 'apis'
import { useSetState } from 'hooks'
import { useAppDispatch } from 'app/hooks'
import { actions } from 'app/profileSlice'
import { copyText } from 'utils/common'

interface Props {
  onClose: () => void
}

const MFADialog: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const [{ open, DeviceName, step, SecretKey, SecretQrUrl, Token1, Token2 }, setState] = useSetState({
    open: true,
    DeviceName: '',
    step: 0,
    SecretKey: '',
    SecretQrUrl: '',
    Token1: '',
    Token2: '',
  })

  const removeDialog = () => {
    setState({ open: false })
    onClose?.()
  }
  const onNext = () => {
    generateOtpSecret({ DeviceName }).then((res: any) => {
      const { SecretKey, SecretQrUrl } = res
      setState({ SecretKey, SecretQrUrl, step: 1 })
    })
  }

  const onCancel = () => {
    removeDialog()
  }

  const onAdd = () => {
    enableMFAVerify({ Token1, Token2 }).then(() => {
      dispatch(actions.updateUser({ MFAEnabled: true }))
      onCancel()
    })
  }

  return (
    <DialogUi open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      <IconButton onClick={onCancel} classes={{ root: classes.close }}>
        <Close htmlColor="#211F1F" />
      </IconButton>
      {step === 0 && (
        <>
          <Typography className={classes.title}>MFA device name</Typography>
          <Typography className={classes.desc}>
            Please enter a device name, which will be used to identify the device's ARN. Maximum length is 64
            characters. Please use alphanumeric and +=,. @-_character.
          </Typography>
          <Typography className={classes.noteTemplate}>Device name</Typography>
          <CustomTextField
            value={DeviceName}
            placeholder=""
            sx={{ width: '100%' }}
            onInputChange={(value) => setState({ DeviceName: value })}
          />
          <Box className={classes.bottom}>
            <Button variant="outlined" onClick={onCancel} color="primary" className={classes.btn}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onNext} color="primary" className={classes.btn} disabled={!DeviceName}>
              Next Step
            </Button>
          </Box>
        </>
      )}
      {step === 1 && (
        <>
          <Typography className={classes.title}>Add MFA</Typography>
          <div className={classes.mfaDesc}>
            <ul className={classes.mfaDescText}>
              <li>
                Install a compatible app such as Google Authenticator, Duo Mobile, or Authy on your mobile device or
                computer.
              </li>
              <li>
                Open the authenticator app and scan the code. Or type the key.{' '}
                <Tooltip title={SecretKey} placement="top" arrow onClick={() => copyText(SecretKey)}>
                  <span style={{ color: '#3333F5' }}>show key</span>
                </Tooltip>
              </li>
              <li>Fill in two consecutive codes from the MFA device.</li>
            </ul>
            <img src={SecretQrUrl} alt="" className={classes.qr} />
          </div>
          <Typography className={classes.noteTemplate}>MFA code 1</Typography>
          <CustomTextField
            value={Token1}
            placeholder=""
            sx={{ width: '100%' }}
            onInputChange={(value) => setState({ Token1: value })}
          />
          <Typography className={classes.noteTemplate}>MFA code 2</Typography>
          <CustomTextField
            value={Token2}
            placeholder=""
            sx={{ width: '100%' }}
            onInputChange={(value) => setState({ Token2: value })}
          />
          <Box className={classes.bottom}>
            <Button variant="outlined" onClick={() => setState({ step: 0 })} color="primary" className={classes.btn}>
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={onAdd}
              color="primary"
              className={classes.btn}
              disabled={!Token1 || !Token2}
            >
              Add MFA
            </Button>
          </Box>
        </>
      )}
    </DialogUi>
  )
}

export default MFADialog
