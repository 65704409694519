import classes from './ContentWrap.module.less'
import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import classnames from 'classnames'
interface Props {
  title?: string
  children?: ReactNode
  hiddenDivider?: boolean
  titleClass?: string
}
const ContentWrap: React.FC<Props> = ({ title, children, hiddenDivider, titleClass }) => {
  return (
    <Box className={classnames(classes.container, { [classes.noDivider]: hiddenDivider })}>
      <Typography className={classnames(classes.title, titleClass)}>{title}</Typography>
      {children}
    </Box>
  )
}
export default ContentWrap
