import Config from 'constants/Config'
import { S3 } from 'aws-sdk'

const s3 = new S3({
  accessKeyId: Config.s3AccessKeyId,
  secretAccessKey: Config.s3SecretAccessKey,
  region: Config.awsRegion,
})

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const uploadFile = (body: Blob | File, fileName: string, ContentType?: string): S3.ManagedUpload => {
  const s3upload = s3.upload({
    Bucket: Config.s3PrivateBucket,
    Key: fileName,
    Body: body,
    ContentType: ContentType,
  })
  return s3upload
}

export const uploadFileWithData = async (body: Blob | File, fileName: string): Promise<string> => {
  const data = await uploadFile(body, fileName, 'audio/wav').promise()
  return data.Location
}

export default uploadFileWithData
